import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../../../redux/hooks'
import { FsCrossViewFilters } from '../../../../../redux/filters/filtersTypes'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import Filters, { FiltersType } from '../../../../../components/features/filters/Filters'
import FsCrossViewPageLayout from '../layout/FsCrossViewPageLayout'
import { FsCrossDashboardContent } from './FsCrossDashboardContent'


export const FsCrossViewPage = () => {
    const { viewId } = useParams()
    const { views } = useAppSelector((state) => state.views)
    const view = views.length > 0 ? views.find((view) => view._id === viewId) : null
    const filters = view ? view.filters as FsCrossViewFilters : null


    if (views.length === 0) {
        return <PrivatePage><Loading /></PrivatePage>
    }

    if (!view) {
        return <Navigate to="/" />
    } else {
        return (
            <PrivatePage title={view.name} period onViewPage>
                <Filters filtersType={FiltersType.VIEWSFSCROSS} filters={filters} />
                <FsCrossViewPageLayout view={view}>
                    <FsCrossDashboardContent />
                </FsCrossViewPageLayout>
            </PrivatePage >
        )
    }
}

