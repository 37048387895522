import { Chip, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { BLACK, BRIC, GREENMAX, LIGHT_MOCK, REDMIN, SECONDARY } from '../../../ui/Colors'
import { Annotation, PrimaryText, TitleBis } from '../../../ui/Text'
import NumberWithSpace from '../../../ui/NumberWithSpace'
import { Units } from '../../../../redux/global/globalTypes'
import { InformationsListConfig } from '../../../../pages/private/Foodservice/Pages/Dashboard/TopFlop'

interface OwnProps {
    difference: number
    isTop: boolean
    border?: boolean
    informationsListConfig: InformationsListConfig[]
    tooltip: string
    lineChartTitle: string
    unit: Units
}

interface InfoListItemProps {
    title: string
    data: number
    unit: string
    tooltip: string
}


export const ChipIndicator = ({ isTop }: { isTop: boolean }) => {
    return (
        <Chip
            label={isTop ? 'MAX' : 'MIN'}
            sx={{
                background: isTop ? SECONDARY : BRIC,
                width: '2.6vw',
                height: '1.6vh',
                fontSize: '0.9vmin',
                mb: '1vh',
                cursor: "default",
                '& span': {
                    paddingLeft: '0.5vw',
                    paddingRight: '0.5vw',
                },
            }}
        />
    )
}

export const InformationListItem = ({
    title,
    data,
    unit,
    tooltip,
}: InfoListItemProps) => {
    return (
        <Tooltip title={tooltip}>
            <Grid item>
                <Annotation color={BLACK}>{title}</Annotation>
                <Annotation color={BLACK} bold>
                    {data === 0 ? (
                        <span>-</span>
                    ) : (
                        <NumberWithSpace number={data} />
                    )}
                    {data !== 0 && unit}
                </Annotation>
            </Grid>
        </Tooltip>
    )
}


export const LineChartTitle = ({ title }: { title: string }) => {
    if (title && title.length > 28) {
        return (
            <Tooltip title={title}>
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        color: BLACK,
                        whiteSpace: 'nowrap',
                        maxWidth: '90%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '1.6vmin',
                        cursor: "default"
                    }}
                >
                    {title}
                </Typography>
            </Tooltip>
        )
    }

    return (
        <PrimaryText
            bold
            color={BLACK}
            whiteSpace="nowrap"
            maxWidth="90%"
            overflow="hidden"
            textOverflow="ellipsis"
        >
            {title}
        </PrimaryText>
    )
}

const LineChartCard = ({ difference, isTop, border = true, informationsListConfig, tooltip, lineChartTitle, unit }: OwnProps) => {

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            sx={{
                borderRight: border ? `1px solid ${LIGHT_MOCK}` : 'none',
                height: '50%',
            }}
        >
            <Grid
                container
                direction="column"
                flex={2}
                sx={{ maxWidth: '66%', pl: '0.5vw' }}
            >
                {border && <ChipIndicator isTop={isTop} />}
                <Tooltip title={tooltip}>
                    <Grid>
                        <LineChartTitle title={lineChartTitle} />
                        <TitleBis
                            color={difference > 0 ? GREENMAX : REDMIN}
                            bold
                        >
                            {difference > 0 ? '+ ' : null}
                            <NumberWithSpace
                                number={Math.round(difference)}
                            />
                            {unit}
                        </TitleBis>
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid item flex={1}>
                {
                    informationsListConfig.map((element, index) => <InformationListItem
                        title={element.title}
                        data={element.data}
                        unit={element.unit}
                        tooltip={element.tooltip}
                        key={index}
                    />
                    )
                }
            </Grid>
        </Grid >
    )
}

export default LineChartCard
