import React from 'react'
import { PrimaryText } from '../../../../ui/Text'
import { BLACK } from '../../../../ui/Colors'
import { Grid, Radio } from '@mui/material'
import { SyntheticEvent } from 'react'

const titleDictionnary = {
    type: 'Type',
}

interface OwnProps {
    label: string
    checked?: boolean
    lengthAccordion?: number
    handleChange?: (
        a: string, lengthAccordion: number
    ) => (event: SyntheticEvent<Element, Event>, expanded: boolean) => void
    panel?: string
}

export default function CheckboxLabels({
    label,
    checked,
    lengthAccordion = 1,
    handleChange,
    panel
}: OwnProps) {
    const printedLabel = titleDictionnary[label] || label

    return (
        <Grid container alignItems="center" >
            {lengthAccordion > 1 && (
                <Radio
                    defaultChecked
                    checked={checked}
                    onClick={() => {
                        handleChange(`${panel}`, lengthAccordion)
                    }}
                />
            )}
            <PrimaryText
                onClick={(e) => {
                    e.preventDefault()
                }}
                color={BLACK}
            >
                {printedLabel}
            </PrimaryText>
        </Grid>
    )
}
