import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import { EnvStatus, UsersStatus } from '../../../../../redux/user/userTypes'
import { fetchDashboard } from '../../../../../redux/global/globalSlice'
import { StandaloneScreen } from '../../../../../components/features/screens/StandAloneScreen'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'

const FsCrossDashboardLayout = ({ children }: { children: JSX.Element }) => {
    const dispatch = useAppDispatch()

    const dashboard = useAppSelector(
        (state) => state.global.fsDashboardData
    )
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)
    const { unit, env } = useAppSelector((state) => state.global)
    const { status, brand } = useAppSelector((state) => state.user.user)

    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }

    useEffect(() => {
        if (brand && activeFsCrossFilters.startDate) {
            dispatch(fetchDashboard(activeFsCrossFilters))
        } else if (activeFsCrossFilters.startDate === '') {
        }
    }, [brand, activeFsCrossFilters, unit])

    if (
        !dashboard ||
        !activeFsCrossFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    if (dashboard.topFlopDashboard && dashboard.topFlopDashboard.length === 0) {
        return <NoDataScreen />
    }
    return <Grid>{children}</Grid>
}
export default FsCrossDashboardLayout
