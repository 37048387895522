import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import CustomerLabels, { labels } from './LabelsCustomer'
import { Grid } from '@mui/material'
import { sum } from './ListLabelsCustomer'
import {
    Segmentation,
    SegmentationType,
} from '../../../../../redux/fsProducts/fsProductsTypes'
import { useAppSelector } from '../../../../../redux/hooks'
import { listOfFSDistributors } from '../../types'
import { EnvName } from '../../../../../redux/user/userTypes'
import { WarningBannerClientSegmentationMetro } from '../Dashboard/FsSegmentation'
import { Units } from '../../../../../redux/global/globalTypes'

ChartJS.register(ArcElement, Tooltip, Legend)

const getType = (index: number): string => {
    switch (index) {
        case 0:
            return 'type'
        case 1:
            return 'subType'
        case 2:
            return 'subSubType'
    }
}

const getSegmentationTooltipContent = (
    envName: string,
    model: any,
    sortedClients: any,
    unit: Units
) => {
    switch (envName) {
        case EnvName.METRO:
            const ca = Math.round(model[0].parsed)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            return `CA : ${ca} ${Units.euro} `
        default:
            const type = getType(model[0].datasetIndex)
            const labelIndex = model[0].dataIndex
            const sells = Math.round(model[0].parsed)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            const clients = sortedClients[type].clients
                ? `\nNombre de clients : ${Math.round(
                    sortedClients[type].clients[labelIndex]
                )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`
                : ''

            return `Volume : ${sells} ${unit} ${clients}`
    }
}

const getSortedClients = (distrib: string, clients: any) => {
    switch (distrib) {
        case EnvName.TRANSGOURMET:
        case EnvName.SODEXO:
        case EnvName.EPISAVEURS:
            const sortedTypeTGM = sortClients(
                clients[SegmentationType.TYPE].data,
                clients[SegmentationType.TYPE].labels,
                clients[SegmentationType.TYPE].percentage
            )
            return {
                type: {
                    data: sortedTypeTGM.data,
                    labels: sortedTypeTGM.labels,
                    percentage: sortedTypeTGM.percentage,
                },
            }
        case EnvName.PASSIONFROID:
            const sortedTypePF = sortClients(
                clients[SegmentationType.TYPE].data,
                clients[SegmentationType.TYPE].labels,
                clients[SegmentationType.TYPE].percentage
            )

            const sortedSubTypePF = sortClients(
                clients[SegmentationType.SUB_TYPE].data,
                clients[SegmentationType.SUB_TYPE].labels,
                clients[SegmentationType.SUB_TYPE].percentage
            )

            const sortedSubSubTypePF = sortClients(
                clients[SegmentationType.SUB_SUB_TYPE].data,
                clients[SegmentationType.SUB_SUB_TYPE].labels,
                clients[SegmentationType.SUB_SUB_TYPE].percentage
            )
            return {
                type: {
                    data: sortedTypePF.data,
                    labels: sortedTypePF.labels,
                    percentage: sortedTypePF.percentage,
                },
                subType: {
                    data: sortedSubTypePF.data,
                    labels: sortedSubTypePF.labels,
                    percentage: sortedSubTypePF.percentage,
                },
                subSubType: {
                    data: sortedSubSubTypePF.data,
                    labels: sortedSubSubTypePF.labels,
                    percentage: sortedSubSubTypePF.percentage,
                },
            }
        case EnvName.TRANSGOURMETPLUS:
            const sortedTypeTGMPlus = sortClients(
                clients[SegmentationType.TYPE].data,
                clients[SegmentationType.TYPE].labels,
                clients[SegmentationType.TYPE].percentage
            )

            const sortedSubTypeTGMPlus = sortClients(
                clients[SegmentationType.SUB_TYPE].data,
                clients[SegmentationType.SUB_TYPE].labels,
                clients[SegmentationType.SUB_TYPE].percentage
            )
            return {
                type: {
                    data: sortedTypeTGMPlus.data,
                    labels: sortedTypeTGMPlus.labels,
                    percentage: sortedTypeTGMPlus.percentage,
                },
                subType: {
                    data: sortedSubTypeTGMPlus.data,
                    labels: sortedSubTypeTGMPlus.labels,
                    percentage: sortedSubTypeTGMPlus.percentage,
                },

            }

        default:
            const sortedType = sortClients(
                clients[SegmentationType.TYPE].data,
                clients[SegmentationType.TYPE].labels,
                clients[SegmentationType.TYPE].percentage,
                clients[SegmentationType.TYPE].clients
            )

            const sortedSubType = sortClients(
                clients[SegmentationType.SUB_TYPE].data,
                clients[SegmentationType.SUB_TYPE].labels,
                clients[SegmentationType.SUB_TYPE].percentage,
                clients[SegmentationType.SUB_TYPE].clients
            )

            const sortedSubSubType = sortClients(
                clients[SegmentationType.SUB_SUB_TYPE].data,
                clients[SegmentationType.SUB_SUB_TYPE].labels,
                clients[SegmentationType.SUB_SUB_TYPE].percentage,
                clients[SegmentationType.SUB_SUB_TYPE].clients
            )
            return {
                type: {
                    data: sortedType.data,
                    labels: sortedType.labels,
                    clients: sortedType.clients,
                    percentage: sortedType.percentage,
                },
                subType: {
                    data: sortedSubType.data,
                    labels: sortedSubType.labels,
                    clients: sortedSubType.clients,
                    percentage: sortedSubType.percentage,
                },
                subSubType: {
                    data: sortedSubSubType.data,
                    labels: sortedSubSubType.labels,
                    clients: sortedSubSubType.clients,
                    percentage: sortedSubSubType.percentage,
                },
            }
    }
}

export const sortClients = (
    data: number[],
    labels: string[],
    percentage: number[],
    clients?: number[]
): Segmentation => {
    const list = []
    data.forEach((elem, index) =>
        list.push({
            base: data[index],
            second: labels[index],
            third: clients ? clients[index] : 0,
            fourth: percentage[index],
        })
    )

    list.sort((a, b) => {
        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    const newData = []
    const newLabels = []
    const newClients = []
    const newPercentage = []
    list.forEach((elem, index) => {
        newData[index] = list[index].base
        newLabels[index] = list[index].second
        newClients[index] = list[index].third
        newPercentage[index] = list[index].fourth
    })

    return {
        data: newData,
        labels: newLabels,
        clients: newClients,
        percentage: newPercentage,
    }
}

const DoughnutChartCustomer = ({ envName }: { envName: string }) => {
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const chartRef = React.useRef()
    const { segmentation: clients } = useAppSelector(
        (state) => state.fsProducts.clients
    )
    const { unit } = useAppSelector((state) => state.global)
    const [labelsHidden, setLabelsHidden] = useState([1, 2])
    const { env } = useAppSelector((state) => state.global)

    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const margin =
        envName === listOfFSDistributors.TRANSGOURMET ||
            listOfFSDistributors.SODEXO
            ? { mt: '3vh', mb: '3vh' }
            : {}
    const options = {
        animation: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const type = getType(model.datasetIndex)
                        const labelIndex = model.dataIndex
                        const total = model.dataset.data.reduce(sum, 0)
                        return `${sortedClients[type].labels[labelIndex]
                            } : ${Math.round((model.parsed / total) * 100)}%`
                    },
                    footer: (model: any) => {
                        const tooltipContent = getSegmentationTooltipContent(
                            envName,
                            model,
                            sortedClients,
                            unit
                        )
                        return tooltipContent
                    },
                },
            },
        },
    }

    const sortedClients = getSortedClients(env.name, clients)

    const getDoughnutChartCustomerConfig = (envName: string) => {
        switch (envName) {
            case listOfFSDistributors.FRANCE_FRAIS:
            case listOfFSDistributors.PASSIONFROID:
            case listOfFSDistributors.METRO:
                return [
                    {
                        data: sortedClients.type.data,
                        backgroundColor: labels[0].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: false,
                    },
                    {
                        data: sortedClients.subType.data,
                        backgroundColor: labels[1].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: labelsHidden.includes(1),
                    },
                    {
                        data: sortedClients.subSubType.data,
                        backgroundColor: labels[2].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: labelsHidden.includes(2),
                    },
                ]
            case listOfFSDistributors.TRANSGOURMET:
            case listOfFSDistributors.SODEXO:
            case listOfFSDistributors.EPISAVEURS:
                return [
                    {
                        data: sortedClients.type.data,
                        backgroundColor: labels[0].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: false,
                    },
                ]
            case listOfFSDistributors.TRANSGOURMETPLUS:
                return [{
                    data: sortedClients.type.data,
                    backgroundColor: labels[0].backgroundColor,
                    borderWidth: 0,
                    borderColor: 'black',
                    hidden: false,
                },
                {
                    data: sortedClients.subType.data,
                    backgroundColor: labels[1].backgroundColor,
                    borderWidth: 0,
                    borderColor: 'black',
                    hidden: labelsHidden.includes(1),
                },]
            default:
                return [
                    {
                        data: sortedClients.type.data,
                        backgroundColor: labels[0].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: false,
                    },
                    {
                        data: sortedClients.subType.data,
                        backgroundColor: labels[1].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: labelsHidden.includes(1),
                    },
                    {
                        data: sortedClients.subSubType.data,
                        backgroundColor: labels[2].backgroundColor,
                        borderWidth: 0,
                        borderColor: 'black',
                        hidden: labelsHidden.includes(2),
                    },
                ]
        }
    }

    return (
        <Grid container height="100%" alignItems="center" sx={margin}>
            <Grid container justifyContent="center" flex={2}>
                <Doughnut
                    updateMode="none"
                    style={{ maxHeight: '25vh', maxWidth: '100%' }}
                    ref={chartRef}
                    options={options as any}
                    data={{
                        datasets: getDoughnutChartCustomerConfig(envName),
                    }}
                />
            </Grid>
            <Grid container flex={5} >
                {isLabels ? (
                    <Grid container justifyContent="center" >
                        {envName === EnvName.METRO && (
                            <WarningBannerClientSegmentationMetro />
                        )}
                        <CustomerLabels
                            envName={envName}
                            chart={chartRef.current}
                            clients={sortedClients}
                            setLabelsHidden={setLabelsHidden}
                        />
                    </Grid>
                ) : (
                    'loading'
                )}
            </Grid>
        </Grid>
    )
}

export default DoughnutChartCustomer
