import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React from "react"
import { GBHSuppliersHeadTabColumns } from "../../utils"
import { useAppSelector } from "../../../../../redux/hooks"

export const SuppliersPerSegment = () => {
    const { suppliers } = useAppSelector((state) => state.fsProducts)
    const { brand } = useAppSelector((state) => state.user.user)

    return (
        <Grid height="50vh">
            <DataTableComponent
                rows={suppliers.segmentsList}
                columns={GBHSuppliersHeadTabColumns("segment", brand && brand)}
                getRowId={(row) => row.segment}
                hasDrillDown={false}
            />
        </Grid >
    )
}