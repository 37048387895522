import React, { useCallback, useEffect, useState } from "react"
import { HEADER_HEIGHT, NAVBAR } from "./Private/PrivatePage"
import { CardActions, CardContent, Grid, useMediaQuery } from "@mui/material"
import { fetchMultiAccountCompanies } from "../../../redux/companies/companiesSlice"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { PrimaryText } from "../../ui/Text"
import { PrimaryButton } from "../../ui/Button"
import { useNavigate } from "react-router-dom"
import Card from '@mui/material/Card';
import { setHeaders } from "../../../api/config"
import { setGlobalEnv } from "../../../redux/global/globalSlice"
import { MobileSidebar, Sidebar } from "../navigation/routing/Sidebar"
import { theme } from "../../../pages/private/DataTableComponent"
import PrivateHeader from "./Private/PrivateHeader"
import { Env, EnvName, EnvStatus, EnvType } from "../../../redux/user/userTypes"
import { fetchUser } from "../../../redux/user/userSlice"
import Loading from "../screens/LoadingScreen"
import SelectSearch from "../filters/selects/SelectSearch"
import { filterData } from "../filters/selects/FiltersFunction"
import { DepthOfData } from "../filters/FilterSelect"
import ChartBlock from "../ChartBlock"

interface Company {
    _id: string
    name: string
}

const CompanyCard = ({ company, handleClick }: { company: Company, handleClick: (company: Company) => void }) => {

    return (<Card sx={{ mb: "3vh", width: "20%", padding: 2 }}>
        <CardContent>
            <PrimaryText>
                {company.name}
            </PrimaryText>
        </CardContent>
        <CardActions>
            <PrimaryButton onClick={() => {
                handleClick(company)
            }}>
                Accéder à la plateforme
            </PrimaryButton>
        </CardActions>
    </Card>)
}

export const MultiAccountPage = () => {
    const dispatch = useAppDispatch()
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const allEnv = useAppSelector((state) => state.user.user.env)
    const [envUsers, setEnvUsers] = useState(null)
    const { multiAccountCompanies } = useAppSelector((state) => state.companies)

    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const contentWidth = isMobile ? '100%' : `calc(88vw - ${NAVBAR}vw)`
    const marginLeft = isMobile ? 0 : `14vw`

    const _fetchMultiAccountCompanies = useCallback(
        async () => await dispatch(fetchMultiAccountCompanies()),
        [dispatch]
    )

    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    useEffect(() => {
        localStorage.removeItem("multiAccountCompanyId");
        setHeaders(null, null)
        _setEnv({
            name: EnvName.SUPER_ADMIN,
            type: EnvType.SUPER_ADMIN,
            status: EnvStatus.ACTIVATED
        })
        _fetchMultiAccountCompanies().then(() => setIsFirstLoad(false))
    }, [])

    // Search part
    const [search, setSearch] = useState('')
    const [list, setList] = useState(multiAccountCompanies)

    const filteredResultsAfterSearch = filterData(multiAccountCompanies, search, DepthOfData.ONE)

    useEffect(() => {
        setList(filteredResultsAfterSearch)
    }, [search, multiAccountCompanies])

    // Navigation
    const navigate = useNavigate()

    const _fetchUser = useCallback(async () => await dispatch(fetchUser()), [dispatch])

    const handleSelectCompany = (company: Company) => {
        setHeaders(null, company._id)
        localStorage.setItem("multiAccountCompanyId", company._id);
        _fetchUser().then(() => setEnvUsers(allEnv))
    }

    useEffect(() => {
        if (envUsers) {
            const envToNavigate = allEnv.filter((env) => env.name !== EnvName.SETTING)
            navigate(`/${envToNavigate[0].type}/${envToNavigate[0].name}/dashboard`)
        }
    }, [allEnv, envUsers])

    return (
        <Grid height="auto">
            <Grid item>{isMobile ? <MobileSidebar /> : <Sidebar />}</Grid>
            <Grid item sx={{ width: contentWidth, marginLeft: marginLeft }}>
                <PrivateHeader
                    title={""}
                />

                <Grid item sx={{ height: `calc(100% - ${HEADER_HEIGHT}vh)`, p: "1vh" }}>
                    <ChartBlock title="Sélectionner ci-dessous l’espace fournisseur auquel vous souhaitez accéder" >
                        <Grid item sx={{ height: "auto", p: "1vw" }}>
                            <Grid item sx={{ width: "20vw" }} >
                                <SelectSearch search={search} setSearch={setSearch} />
                            </Grid>
                            {isFirstLoad ? <Loading /> : <Grid container pt="4vh" flexWrap="wrap" justifyContent="space-between">
                                {list.map((company, index) => {
                                    return (
                                        <CompanyCard company={company} key={index} handleClick={handleSelectCompany} />
                                    )
                                })}
                            </Grid>}
                        </Grid>
                    </ChartBlock>

                </Grid>
            </Grid>
        </Grid >
    )
}