import React, { useEffect, useState, useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { useAppSelector } from '../../../../../redux/hooks'
import { monthsAsObject } from '../../../../../helpers/months'
import { sortFiveArrSameWay, sortTwoArrSameWay } from '../../../../../helpers/sort'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../../helpers/ExternalStore'
import { Histogram } from '../../../../../components/features/foodService/dashboard/histogram/Histogram'
import { dashboardLabels } from '../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'
import { EnvName } from '../../../../../redux/user/userTypes'
import { Units } from '../../../../../redux/global/globalTypes'
import { NoDataPlaceholder } from '../../../../../components/features/foodService/dashboard/utils/NoDataPlaceHolder'
import { AnyAaaaRecord } from 'node:dns'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)


const isAllDataNull = (histogram: any): boolean => {
    const checkNullValues = (arr?: number[]) => arr ? arr.every(value => value === 0) : true;

    return (
        checkNullValues(histogram.current.data) &&
        checkNullValues(histogram.current.shareVolumeData) &&
        checkNullValues(histogram.current.shareCaData) &&
        checkNullValues(histogram.current.caData) &&
        checkNullValues(histogram.old.data) &&
        checkNullValues(histogram.old.shareVolumeData) &&
        checkNullValues(histogram.old.shareCaData) &&
        checkNullValues(histogram.old.caData)
    );
};

const getTooltipOptions = (distrib, sortedCurrent, sortedOld, unit) => {
    switch (distrib) {
        case EnvName.GBH:
            return {
                title: (model: any) => {
                    const month = monthsAsObject[model[0].label.slice(5, 7)]
                    const year =
                        model[0].datasetIndex === 0
                            ? model[0].label.slice(0, 4)
                            : parseInt(model[0].label.slice(0, 4)) + 1

                    return `${month} ${year}`
                },
                label: (model: any) => {
                    return model.datasetIndex === 1
                        ? ``
                        : ``
                },
                afterLabel: (model: any) => {
                    const value = Math.round(model.raw)

                    const oldValue = Math.round(
                        sortedOld.second[model.dataIndex]
                    )

                    const currentCa = Math.round(
                        sortedCurrent.third[model.dataIndex]
                    )

                    const oldCa = Math.round(
                        sortedOld.third[model.dataIndex]
                    )

                    const currentShareVolume = Math.round(
                        sortedCurrent.fourth[model.dataIndex]
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    const curentShareCa = Math.round(
                        sortedCurrent.fifth[model.dataIndex]
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    const oldShareVolume = Math.round(
                        sortedOld.fourth[model.dataIndex]
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    const oldShareCa = Math.round(
                        sortedOld.fifth[model.dataIndex]
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    const differenceValue = value - oldValue

                    const differenceVolumeDisplay = differenceValue === 0
                        ? ''
                        :
                        differenceValue > 0
                            ? `Évolution en ${Units.uvc} : ↑ ${Math.round(
                                (differenceValue * 100) / oldValue
                            )
                            } % `
                            : `Évolution en ${Units.uvc} : ↓ ${Math.round(
                                (differenceValue * 100) / oldValue
                            )
                            } % `


                    const differenceCa = currentCa - oldCa

                    const differenceCaDisplay = differenceCa === 0
                        ? ''
                        :
                        differenceCa > 0
                            ? `Évolution en ${Units.ca} : ↑ ${oldCa !== 0 ? Math.round(
                                (differenceCa * 100) / oldCa
                            ) : "-"
                            } % `
                            : `Évolution en ${Units.ca} : ↓ ${oldCa !== 0 ? Math.round(
                                (differenceCa * 100) / oldCa
                            ) : "-"
                            } % `

                    return model.datasetIndex === 1
                        ? `En ${Units.uvc}\n   Volume A : ${value.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}\n   ${differenceVolumeDisplay} - PDM A : ${currentShareVolume} %\n---\n En ${Units.euro}\n   Volume A: ${currentCa.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}\n   ${differenceCaDisplay} -  PDM A : ${curentShareCa} %`
                        : `Volume A - 1 en ${Units.euro}: ${oldCa.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}\nPDM A - 1 en ${Units.uvc} : ${oldShareVolume} % \n PDM A - 1 en ${Units.euro} : ${oldShareCa} %`
                }
            }
        default:
            return {
                title: (model: any) => {
                    const month = monthsAsObject[model[0].label.slice(5, 7)]
                    const year =
                        model[0].datasetIndex === 0
                            ? model[0].label.slice(0, 4)
                            : parseInt(model[0].label.slice(0, 4)) + 1

                    return `${month} ${year}`
                },
                label: (model: any) => {
                    const value = Math.round(model.raw)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    return model.datasetIndex === 1
                        ? `Volume A : ${value} ${unit}`
                        : `Volume A-1 : ${value} ${unit}`
                },
                afterLabel: (model: any) => {
                    const value = Math.round(
                        sortedOld.second[model.dataIndex]
                    )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    return model.datasetIndex === 1
                        ? `Volume A-1 : ${value} ${unit}`
                        : ''
                },
                footer: (model: any) => {
                    const value = model[0].raw
                    const valueLastYear =
                        sortedOld.second[model[0].dataIndex]
                    const difference = value - valueLastYear

                    if (valueLastYear === 0) {
                        return 'Évolution : -'
                    }

                    return model[0].datasetIndex === 1
                        ? difference === 0
                            ? ''
                            : difference > 0
                                ? `Évolution : ↑ ${Math.round(
                                    (difference * 100) / valueLastYear
                                )} %`
                                : `Évolution : ↓ ${Math.round(
                                    (difference * 100) / valueLastYear
                                )} %`
                        : ''
                },
            }
    }
}

export const HistogramFS = () => {
    const chartRef = React.useRef()
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const { name } = useAppSelector((state) => state.global.env)
    const { histogramDashboard } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    const dataEmpty = isAllDataNull(histogramDashboard)


    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const sortedOld = (distrib) => {
        switch (distrib) {
            case EnvName.GBH:
                return sortFiveArrSameWay(
                    histogramDashboard.old.labels,
                    histogramDashboard.old.data,
                    histogramDashboard.old.caData,
                    histogramDashboard.old.shareVolumeData,
                    histogramDashboard.old.shareCaData,
                    true
                )
            default:
                return sortTwoArrSameWay(
                    histogramDashboard.old.labels,
                    histogramDashboard.old.data,
                    true
                )
        }
    }

    const sortedCurrent = (distrib) => {
        switch (distrib) {
            case EnvName.GBH:
                return sortFiveArrSameWay(
                    histogramDashboard.current.labels,
                    histogramDashboard.current.data,
                    histogramDashboard.current.caData,
                    histogramDashboard.current.shareVolumeData,
                    histogramDashboard.current.shareCaData,
                    true
                )
            default:
                return sortTwoArrSameWay(
                    histogramDashboard.current.labels,
                    histogramDashboard.current.data,
                    true
                )
        }
    }

    const dataLastYearIsNull =
        sortedOld(name).second.length === 1 && sortedOld(name).second[0] === 0

    const data = {
        labels: sortedOld(name).base,
        datasets: dataLastYearIsNull
            ? [
                {
                    data: sortedCurrent(name).second,
                    backgroundColor: dashboardLabels[1].backgroundColor,
                    borderColor: dashboardLabels[1].backgroundColor,
                },
            ]
            : [
                {
                    data: sortedOld(name).second,
                    backgroundColor: dashboardLabels[0].backgroundColor,
                    borderColor: dashboardLabels[0].backgroundColor,
                },
                {
                    data: sortedCurrent(name).second,
                    backgroundColor: dashboardLabels[1].backgroundColor,
                    borderColor: dashboardLabels[1].backgroundColor,
                },
            ],
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                    callback: (value) => {
                        return monthsAsObject[sortedOld(name).base[value].slice(5, 7)]
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: getTooltipOptions(name, sortedCurrent(name), sortedOld(name), unit)
            },
        }
    }

    return (
        dataEmpty ? (<NoDataPlaceholder />) :
            (<Histogram labels={dashboardLabels} data={data} options={options} height="16vh" />)
    )
}
