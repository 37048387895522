import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { AppThunk } from '../store'
import { EnvType } from '../user/userTypes'
import { HistoricList, importsState, Historic } from './importsTypes'

const initialState: importsState = {
    FsHistoric: { list: [], lastImport: [] },
    RetailHistoric: { list: [], lastImport: [] },
    importStatus: []
}

const importsSlice = createSlice({
    name: 'imports',
    initialState,
    reducers: {
        setRetailImports: (state, action: PayloadAction<Historic>) => {
            state.RetailHistoric = action.payload
        },
        setFsImports: (state, action: PayloadAction<Historic>) => {
            state.FsHistoric = action.payload
        },
        setImportsStatus: (state, action: PayloadAction<any>) => {
            state.importStatus = action.payload
        },
        addFsImport: (state, action: PayloadAction<HistoricList>) => {
            state.FsHistoric.list = [action.payload, ...state.FsHistoric.list]
        },
        addRetailImport: (state, action: PayloadAction<HistoricList>) => {
            state.RetailHistoric.list = [
                action.payload,
                ...state.RetailHistoric.list,
            ]
        },
    },
})

export const { addRetailImport, setRetailImports, setFsImports, addFsImport, setImportsStatus } =
    importsSlice.actions
export default importsSlice.reducer

export const fetchImports =
    (type: EnvType.FOOD_SERVICE | EnvType.RETAIl): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            try {
                await client
                    .get(`/imports?type=${type}`)
                    .then((response) => {
                        if (type === EnvType.FOOD_SERVICE) {
                            dispatch(setFsImports(response.data))
                        } else {
                            dispatch(setRetailImports(response.data))
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                    })
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchImportsStatus =
    (type: EnvType.FOOD_SERVICE | EnvType.RETAIl, period: string[]): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            try {
                await client
                    .post(`/imports/status?type=${type}`, { period: period })
                    .then((response) => {
                        dispatch(setImportsStatus(response.data))
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                    })
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const handleAddImport =
    (data: Partial<HistoricList>): AppThunk<Promise<string | null>> =>
        async (dispatch, getState) => {
            try {
                const insertedId = await client
                    .post('/imports', data)
                    .then((response) => {
                        if (data.envType === EnvType.FOOD_SERVICE) {
                            dispatch(
                                addFsImport({
                                    _id: response.data.insertedId,
                                    created_at: new Date().toLocaleDateString(
                                        'fr-FR'
                                    ),
                                    ...data,
                                } as HistoricList)
                            )
                        } else {
                            dispatch(
                                addRetailImport({
                                    _id: response.data.insertedId,
                                    created_at: new Date().toLocaleDateString(
                                        'fr-FR'
                                    ),
                                    ...data,
                                } as HistoricList)
                            )
                        }
                        return response.data.insertedId
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                return insertedId
            } catch (e) {
                console.log('ERROR : ', e)
                return null
            }
        }
