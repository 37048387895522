import React from "react";
import { PrimaryText } from "../../ui/Text";
import { BLACK, WHITE } from "../../ui/Colors";
import ConstructionIcon from '@mui/icons-material/Construction';
import { ImportSatus } from "../../../pages/private/Global/Import/Import";

const getColor = (value) => {
    if (value === ImportSatus.INTEGRATED) return "#8095FF";
    if (value === ImportSatus.IN_PROGRESS || value === "missed") return WHITE;
    return "#000000";
};

const automatedImport = ["France Frais"]

const HeatMapTable = ({ data }: { data: any }) => {
    const months = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const monthsToDisplay = months.slice(currentDate.getMonth() + 1).concat(months.slice(0, currentMonth + 1))

    return (
        <table style={{ tableLayout: "auto", width: "60%" }}>
            <thead>
                <th />
                {monthsToDisplay.map((month) => (
                    <th style={{ width: "2vw", textAlign: "center" }}>
                        <PrimaryText color={BLACK}>{month}</PrimaryText>
                    </th>
                ))}
            </thead>
            <tbody>
                {data && data.map((row) => (
                    <tr>
                        <td style={{ paddingLeft: "2vw", width: "6vw" }}>
                            <PrimaryText color={BLACK}>
                                {row.name}
                            </PrimaryText>
                        </td>
                        {
                            row.status.map((value, index) => {
                                return (
                                    <td
                                        style={{
                                            textAlign: "center",
                                            backgroundColor: getColor(value),
                                            border: "1px  #8095FF",
                                            borderStyle: value === "in-progress" ? "dotted" : "solid",
                                            borderRadius: "5px"
                                        }}
                                    >
                                        {value === ImportSatus.IN_PROGRESS ? <ConstructionIcon sx={{ color: "#8095FF" }} /> : ""}
                                    </td>
                                )
                            })
                        }
                    </tr>
                ))}
                {automatedImport.map((distrib) =>
                    <tr>
                        <td style={{ paddingLeft: "2vw", width: "6vw" }}>
                            <PrimaryText color={BLACK}>
                                {distrib}
                            </PrimaryText>
                        </td>
                        <td colSpan={months.length} style={{ backgroundColor: "#8095FF", borderRadius: "5px", textAlign: "center" }}>
                            <PrimaryText color={WHITE} >
                                Import automatisé
                            </PrimaryText>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default HeatMapTable