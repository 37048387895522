import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../redux/hooks'
import { supabase } from '../../../supabaseClient.js'
import { Form, type FormikBag, type FormikProps, withFormik } from 'formik'
import { string } from 'yup'
import { PASSWORD_LENGTH } from './SignIn'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import ErrorMessage from '../../../components/ui/ErrorMessage'
import PublicPage from '../../../components/features/GlobalPages/PublicPage'
import { Alert, AlertTitle, Grid, Slide } from '@mui/material'
import PublicForm, {
    TextAlignement,
} from '../../../components/features/PublicForm'
import { PrimaryText } from '../../../components/ui/Text'
import { PrimaryButton } from '../../../components/ui/Button'
import * as Yup from 'yup'
import { InputPasswordReveal } from '../../../components/ui/Inputs'

interface FormValues {
    password: string
}

const supabaseErrorMessage = {
    'New password should be different from the old password.':
        "Votre nouveau mot de passe doit être différent de l'ancien",
    default: 'Erreur, merci de réessayer',
}

interface FormProps {
    user: any
    error: boolean
    errorMessage: string
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PureSetNewPasswordForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    useEffect(() => {
        props.resetForm()
    }, [props.error])

    const errorMessageToDisplay = supabaseErrorMessage[props.errorMessage]
        ? supabaseErrorMessage[props.errorMessage]
        : supabaseErrorMessage.default

    return (
        <Form>
            <Grid container wrap="wrap">
                <InputPasswordReveal name="password" label="Nouveau mot de passe" />
                <InputPasswordReveal
                    name="passwordConfirmation"
                    label="Confirmation du nouveau mot de passe"
                />
                {props.errorMessage ? (
                    <ErrorMessage msg={errorMessageToDisplay} />
                ) : null}
                <Box mt={5} sx={{ width: '100%' }}>
                    <PrimaryButton fullWidth type="submit">
                        J'envoie mes nouvelles informations
                    </PrimaryButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledPasswordRecoveryForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        password: '',
        passwordConfirmation: '',
    }),
    validationSchema: () => {
        return Yup.object().shape({
            password: string()
                .min(
                    PASSWORD_LENGTH,
                    `Le champ mot de passe doit contenir au moins ${PASSWORD_LENGTH} caractères.`
                )
                .required('Le champ Mot de passe est obligatoire.'),
            passwordConfirmation: string().oneOf(
                [Yup.ref('password'), null],
                'Les mots de passe ne sont pas identiques'
            ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSetNewPasswordForm)

export default function SetNewPassword() {
    const user = useAppSelector((state) => state.user)
    const navigate = useNavigate()
    const [signError, setSignError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [isSuccess, setSucess] = useState<boolean>(false)

    useEffect(() => {
        if (isSuccess) {
            const interval = setInterval(() => {
                navigate('/')
            }, 4500)
            return () => {
                clearInterval(interval)
            }
        }
    }, [isSuccess, navigate])

    const handleSubmit = async (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        const { error } = await supabase.auth.updateUser({
            password: values.password,
        })

        if (error) {
            setSignError(true)
            setSucess(false)
            setErrorMessage(error.message)
        } else {
            setSucess(true)
        }
    }

    return (
        <PublicPage>
            <Box>
                <PublicForm
                    align={TextAlignement.LEFT}
                    title="Réinitialisation"
                    subtitle={
                        <PrimaryText>
                            Indiquez votre nouveau mot de passe
                        </PrimaryText>
                    }
                >
                    <ControlledPasswordRecoveryForm
                        onSubmit={handleSubmit}
                        error={signError}
                        user={user}
                        errorMessage={errorMessage}
                    />
                </PublicForm>
                <Slide direction="up" in={isSuccess} mountOnEnter unmountOnExit>
                    <Alert
                        severity="success"
                        sx={{
                            width: '25%',
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            fontSize: '1em',
                            padding: '1em',
                        }}
                    >
                        <AlertTitle>Succès</AlertTitle>
                        Vos changements ont été pris en compte
                        <strong style={{ marginLeft: '5px' }}>
                            Vous pouvez désormais identifier
                        </strong>
                    </Alert>
                </Slide>
            </Box>
        </PublicPage>
    )
}
