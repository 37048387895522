import React, { useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LegendItem,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box, Grid } from '@mui/material'
import { BRIC, SECONDARY } from '../../../ui/Colors'
import { useAppSelector } from '../../../../redux/hooks'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'
import { formateLegend } from './utils/formateProductsData'
import { backgroundColor } from '../../../../pages/private/Foodservice/Pages/ProductsPage/LineChartProduct'
import { LinkButton } from '../../../ui/Button'
import { EnvName, LineChartDashboard, Products } from '../../../../redux/user/userTypes'
import { NoDataPlaceholder } from './utils/NoDataPlaceHolder'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

interface DataSet {
    label: string,
    data: number[],
    dataLastYear: number[],
    borderColor: string,
    backgroundColor: string
    unitVolume?: number[]
    evolutionUnitVolume?: number[]
}

interface Data {
    datasets: DataSet[]
}

const LineChart = ({ topFlopDashboard, lineChartDashboard }: { topFlopDashboard: Products[], lineChartDashboard: LineChartDashboard }) => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const { unit, env } = useAppSelector((state) => state.global)
    const emptyData = lineChartDashboard.formatedProductsChart.every(value => value.data.every((value) => value === 0))

    const formatedLegend = formateLegend(lineChartDashboard.legend)
    const datasets = lineChartDashboard.formatedProductsChart.map((product, index) => {
        let color = null
        if (product.name === topFlopDashboard[0]._id) {
            color = SECONDARY
        } else if (product.name === topFlopDashboard[topFlopDashboard.length - 1]._id) {
            color = BRIC
        } else {
            color = backgroundColor[index % 33]
        }

        return {
            label: product.name,
            data: [null, ...product.data, null],
            dataLastYear: [null, ...product.evolution, null],
            borderColor: color,
            backgroundColor: color,
            ...product.unitVolume && { unitVolume: [null, ...product.unitVolume, null] },
            ...product.evolutionUnitVolume && { evolutionUnitVolume: [null, ...product.evolutionUnitVolume, null] },
        }
    })

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    const options = {
        scales: {
            x: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.004,
        borderWidth: height * 0.003,
        plugins: {
            legend: {
                display: true,
                labels: {
                    filter: (legendItem: LegendItem, data: Data) => {
                        const index = data.datasets.findIndex(
                            (dataset: DataSet) => dataset.label === legendItem.text
                        )
                        return index < 4
                    },
                    color: '#000',
                    font: {
                        size: height * 0.014,
                        family: ['Cabin', 'sans-serif'],
                    },
                    boxWidth: height * 0.01,
                    boxHeight: height * 0.01,
                },
                onClick: () => null,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {

                        return ` ${model.dataset.label}`
                    },
                    footer: (model: any) => {
                        const index = model[0].dataIndex
                        const value = Math.round(model[0].raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const valueToDisplay = `Volume A : ${value} ${unit}`

                        const evolution =
                            model[0].dataset.dataLastYear[
                                index] === undefined
                                ? null
                                : Math.round(
                                    model[0].dataset.dataLastYear[
                                    index]
                                )

                        const evolutionToDisplay = evolution === null ? '' : evolution === 0 ? 'Évolution vs. A-1 : -' : evolution > 0
                            ? `Évolution vs. A-1 : ↑ ${evolution} %`
                            : `Évolution vs. A-1 : ↓  ${evolution} %`
                        let unitVolumeToDisplay = ''
                        let evolutionUnitVolumeToDisplay = ''

                        if (model[0].dataset.unitVolume) {
                            const unitVolume = Math.round(model[0].dataset.unitVolume[index]).toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            unitVolumeToDisplay = `Volume A : ${unitVolume} UVC`

                            const evolutionUnitVolume = Math.round(model[0].dataset.evolutionUnitVolume[index]).toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                            evolutionUnitVolumeToDisplay = evolutionUnitVolume === null ? '' : evolutionUnitVolume === "0" ? 'Évolution vs. A-1 : -' : evolutionUnitVolume > "0"
                                ? `Évolution vs. A-1 : ↑ ${evolutionUnitVolume} %`
                                : `Évolution vs. A-1 : ↓ ${evolutionUnitVolume} %`
                        }

                        if (env.name === EnvName.C10 || env.name === EnvName.DISTRIBOISSONS) {
                            return `\nEn L\n   ${valueToDisplay} \n   ${evolutionToDisplay}\n\n---\n\nEn UVC\n   ${unitVolumeToDisplay} \n   ${evolutionUnitVolumeToDisplay}\n`
                        }

                        return `\n${valueToDisplay}\n${evolutionToDisplay}\n`
                    },
                },
            },
        },
    }

    return (
        emptyData ? (<NoDataPlaceholder />) : (
            <Grid container sx={{
                justifyContent: "center",
            }}>
                <Box
                    sx={{
                        height: '24vh',
                        width: '60vw',
                        justifyContent: "center",
                        display: 'inline-block',
                        position: 'relative',
                    }}
                >
                    <Line options={options as any} data={data} />
                    {env.type !== "food-service-cross" && datasets.length > 3 &&
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                            }}
                        >
                            <LinkButton
                                to={`/food-service/${env.name}/produits`}>
                                Voir plus
                            </LinkButton>
                        </Box>}
                </Box>
            </Grid>
        )
    )
}

export default LineChart
