import React from 'react'
import { DataGrid, GridRenderCellParams } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import { SecondaryText } from '../../../../../components/ui/Text'
import NumberWithSpace from '../../../../../components/ui/NumberWithSpace'
import { roundIfNotZero } from '../../../../../helpers/roundIfNotZero'
import { BLACK } from '../../../../../components/ui/Colors'
import Tendency, { TooltipMessage } from '../../../../../components/ui/Tendency'
import { Units } from '../../../../../redux/global/globalTypes'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { EnvName } from '../../../../../redux/user/userTypes'
import { useAppSelector } from '../../../../../redux/hooks'

export const FsCrossTable = ({ distributorsList }) => {
    const { unit } = useAppSelector((state) => state.global)

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
                hideFooter
                columns={crossColumns(unit)}
                rows={distributorsList}
            />
        </Box>
    )
}

export const crossColumns = (unit: Units) =>
    [
        {
            field: 'id',
            headerName: 'Distributeurs',
            editable: false,
            flex: 1,
        },
        {
            field: 'volume',
            headerName: `Volume (${unit})`,
            editable: false,
            flex: 1,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace number={roundIfNotZero(params.value)} />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            flex: 0.8,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={unit}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'vmm',
            headerName: 'VMM',
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace number={roundIfNotZero(params.value)} />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'dn',
            headerName: 'DN',
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {

                if (params.row.id === EnvName.CERCLEVERT || params.row.id === EnvName.TRANSGOURMET) {
                    return (
                        <SecondaryText color={BLACK}>
                            N/A
                        </SecondaryText>
                    )
                }

                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace number={roundIfNotZero(params.value)} />
                    </SecondaryText>
                )
            },
        },
        {
            headerName: '% des ventes',
            field: 'percentage',
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace number={roundIfNotZero(params.value)} />
                    </SecondaryText>
                )
            },
        },
    ]
