import { Box, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search';

interface OwnProps {
    search: string
    setSearch: (string: string) => void
}

const SelectSearch = ({ search, setSearch }: OwnProps) => {
    return (
        <Box position="relative" width="100%">

            <TextField
                sx={{
                    height: '3.5vh',
                    '& div': {
                        height: '100%',
                        fontSize: { xs: '16px', md: '1.4vmin' },
                    },
                }}
                variant="outlined"
                fullWidth
                type="text"
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                }}
                placeholder="Rechercher..."
                onKeyDown={(e) => {
                    e.stopPropagation()
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment:
                        search !== '' ? (
                            <InputAdornment
                                position="end"
                                onClick={() => {
                                    setSearch('')
                                }}
                                style={{ outline: 'none', cursor: 'pointer' }}
                            >
                                <CloseIcon sx={{ width: '1.2vw' }} />
                            </InputAdornment>
                        ) : null,
                }}
            />
        </Box>
    )
}

export default SelectSearch
