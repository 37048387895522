import React from 'react'
import DoughnutChart from '../../../../../components/features/foodService/dashboard/graph/DoughnutChart'
import { useAppSelector } from '../../../../../redux/hooks'
import Stack from '@mui/material/Stack'
import { Button, Grid } from '@mui/material'
import { PrimaryText } from '../../../../../components/ui/Text'
import { Clients, EnvName, EnvType, SegmentationGBH, WarehousesSegmentationC10AndDistriboissons } from '../../../../../redux/user/userTypes'
import { Units } from '../../../../../redux/global/globalTypes'
import { BLACK, DARK_GREY } from '../../../../../components/ui/Colors'
import UploadIcon from '@mui/icons-material/Upload';
import { getPlaceholderDates } from '../../../../../helpers/months'
import { NoDataPlaceholder } from '../../../../../components/features/foodService/dashboard/utils/NoDataPlaceHolder'

export const MetroNoClientsData = () => {
    const { env } = useAppSelector(state => state.global)
    const { startDate, endDate } = useAppSelector(state => env.type === EnvType.FOOD_SERVICE_CROSS ? state.filters.activeFsCrossFilters : state.filters.activeFsFilters)
    const period = getPlaceholderDates(
        new Date(startDate),
        new Date(endDate)
    )

    return (
        <Grid alignItems="center" justifyContent="space-evenly" direction="column" container p="1vw" textAlign="center">
            <Grid>
                <UploadIcon sx={{ mb: "1vh" }} />
                <PrimaryText color={BLACK}>
                    Pour accéder à la segmentation d'utilisateurs finaux METRO, transmettez-nous vos fichiers de données METRO "CIM 2" correspond à la période suivante :
                </PrimaryText>
                <PrimaryText mt="2vh" color={BLACK} bold>{period}</PrimaryText>
            </Grid>
            <Grid>
                <PrimaryText color={BLACK}>
                    Vous pouvez joindre vos fichiers par mail à l'adresse suivante
                </PrimaryText>
                <Button sx={{ fontSize: { xs: '14px', md: '1.3vmin' }, color: DARK_GREY }} href={"mailto: hind@karyonfood.com"}> hind@karyonfood.com</Button>
            </Grid>
        </Grid>
    )
}

export const WarningBannerClientSegmentationMetro = () => {
    return (
        <Stack
            sx={{ width: '95%', height: "4vh", mt: '0.5vh', p: '0.3vh', background: '#F2F2F2' }}
        >
            <PrimaryText style={{ fontSize: "1.52vh", textAlign: "center" }}>
                <b>Attention</b> : La segmentation par type d’utilisateur final
                est en part de CA ({Units.euro}). Ce diagramme circulaire est indépendant des
                filtres Références et Dépôts.
            </PrimaryText>
        </Stack>
    )
}


export const WarningBannerClientSegmentationMetroFsCross = () => {
    return (
        <Stack
            sx={{ width: '95%', height: "4vh", mt: '0.5vh', p: '0.3vh', background: '#F2F2F2' }}
        >
            <PrimaryText style={{ fontSize: "1.52vh", textAlign: "center" }}>
                <b>Attention</b> : La segmentation par type d’utilisateur final
                n'est actuellement pas disponible chez Metro. Conseil : désélectionnez Métro du filtre distributeurs.
            </PrimaryText>
        </Stack>
    )
}
const FsSegmentation = ({
    setFieldSelected,
    setOpen,
    withLegend,
    segmentationDashboard,
    hasDrillDownOnSegmentation = true,
    segmentationLink
}: {
    setFieldSelected?: (field: any) => void
    setOpen?: (open: boolean) => void
    withLegend?: boolean
    segmentationDashboard: Clients | SegmentationGBH | WarehousesSegmentationC10AndDistriboissons
    hasDrillDownOnSegmentation?: boolean
    segmentationLink: string
}) => {
    const { name } = useAppSelector((state) => state.global.env)

    return (
        <Grid height="100%" container justifyContent="center">
            {name === EnvName.METRO && segmentationDashboard && Object.keys(segmentationDashboard).length === 0 ?
                <MetroNoClientsData /> :
                segmentationDashboard && Object.keys(segmentationDashboard).length === 0 ?
                    <NoDataPlaceholder />
                    :
                    <>
                        {name === EnvName.METRO && (
                            <WarningBannerClientSegmentationMetro />
                        )}
                        <DoughnutChart
                            withLegend={withLegend}
                            setOpen={setOpen}
                            setFieldSelected={setFieldSelected}
                            hasDrillDownOnSegmentation={hasDrillDownOnSegmentation}
                            segmentationLink={segmentationLink}
                        />
                    </>
            }
        </Grid>

    )
}

export default FsSegmentation
