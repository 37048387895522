import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { CustomersPageMetro } from '../../../../../../pages/private/Foodservice/Metro/CustomersPage'
import { WarehousesPageMetro } from '../../../../../../pages/private/Foodservice/Metro/WarehousesPage'
import { ProductsPageMetro } from '../../../../../../pages/private/Foodservice/Metro/ProductsPage'
import { ViewPage } from '../../../../views/ViewPage'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'
import { PlaybookPromo } from '../../../../../../pages/private/Foodservice/PlaybookPromo'

export const MetroRouter = (companyModules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageMetro />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageMetro />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageMetro />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
    ...(companyModules.includes(KaryonModules.PROMO) ?
        [] :
        [{
            path: 'promoDemo/',
            element: <PlaybookPromo />,
            title: PagesName.PROMODEMO,
        },]),
]
