import { Button, Grid } from '@mui/material'
import KaryonLogo from '../../../../../../assets/logo-pomme.png'
import React, { useCallback } from 'react'
import EmailContacts from '../../../../../../assets/contact-1.svg'
import InterviewContact from '../../../../../../assets/contact-2.svg'
import MobileContacts from '../../../../../../assets/contact-3.svg'
import { Form, FormikBag, FormikProps, withFormik } from 'formik'
import { object, string } from 'yup'
import { EditValues } from '../../../ProfilPage/ProfilPage'
import { SubTitle } from '../../../../../../components/ui/Text'
import {
    ContactConfig,
    ContactSteps,
} from '../../../../../../redux/contact/contactTypes'
import { fetchUpdateUser } from '../../../../../../redux/user/userSlice'
import { User } from '../../../../../../redux/user/userTypes'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks'
import {
    DialogFooter,
    DialogFooterOneAction,
} from '../../../../../../components/features/Dialog/BasicFooterDialog'
import { DialogHeader } from '../../../../../../components/features/Dialog/BasicDialog'
import { CLICK, createAskForContactActivity, setupContact } from '../../../../../../redux/contact/contactSlice'
import {
    EnhancedField,
    TextInput,
} from '../../../../../../components/ui/Inputs'
import { DARK_GREY } from '../../../../../../components/ui/Colors'
import { StepConfig } from '../../../../../../redux/views/viewsTypes'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

interface OwnProps {
    step: number
    config: StepConfig
    img?: string
    text?: string
    submit?: FormikProps<EditValues> & FormProps
}

interface FormValues {
    phoneNumber: string
}

interface FormProps {
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
    img: string
    config: StepConfig
}
const imgs = [
    {
        text: 'Être recontacté(e)',
        img: MobileContacts,
        steps: [
            ContactSteps.Default,
            ContactSteps.MobileContact,
            ContactSteps.MobileValidation,
        ],
    },
    {
        text: 'Planifier un rendez-vous',
        img: EmailContacts,
        steps: [ContactSteps.Default, ContactSteps.EmailContact],
    },
    {
        text: 'Envoyer un email',
        img: InterviewContact,
        steps: [ContactSteps.Default, ContactSteps.Appointment],
    },
]

export const MobileContact = (
    props: FormikProps<EditValues> & FormProps & OwnProps
): JSX.Element => {
    return (
        <Form style={{ height: "100%" }}>
            <Grid container direction="column" height="100%" justifyContent="space-between">
                <DialogHeader
                    img={KaryonLogo}
                    title="Être recontacté(e)"
                    setOpen={() => {
                        props.config.handleReset()
                    }}
                />
                <Grid textAlign="center">
                    <SubTitle>
                        Pour intégrer les données d’un distributeur
                        supplémentaire, vous devez bénéficier d’un abonnement
                        multi-distributeurs.<br />Discutons-en !
                    </SubTitle>
                </Grid>
                <Grid container>
                    <Grid item flex={1} textAlign="center">
                        <img style={{ width: '50%' }} src={props.img} />
                    </Grid>
                    <Grid container flex={1} direction="column" justifyContent="center">
                        <Grid item>
                            {props.img === MobileContacts ? (
                                <SubTitle textAlign="center" mb="4vh" bold>
                                    A quel numéro souhaitez-vous être recontacté(e)
                                    ?
                                </SubTitle>
                            ) : (
                                <SubTitle textAlign="center" bold>
                                    Demande envoyée !
                                </SubTitle>
                            )}
                        </Grid>
                        <Grid item width="70%" margin="auto">
                            {props.img === MobileContacts ? (
                                <EnhancedField
                                    label="Téléphone"
                                    type="tel"
                                    name="phoneNumber"
                                    autoFocus
                                    component={TextInput}
                                />
                            ) : (
                                <SubTitle>
                                    Vous allez être recontacté(e) sous peu ...
                                </SubTitle>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <DialogFooter
                    leftButtonFunc={() => {
                        props.config.handlePrevious()
                    }}
                    leftButtonText="Précédent"
                    rightButtonFunc={() => {
                        if (props.img === MobileContacts) {
                            props.handleSubmit()
                            if (props.isValid) props.config.handleNext()
                        } else props.config.handleReset()
                    }}
                    rightButtonText="Valider"
                />
            </Grid>
        </Form>
    )
}

const ControlledPhoneNumberForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props: FormProps) => ({
        phoneNumber: props.initialValues.phoneNumber,
    }),
    validationSchema: () => {
        return object().shape({
            phoneNumber: string().matches(phoneRegExp, "Merci d'entrer un numéro de téléphone valide").length(10, "Merci d'entrer un numéro de téléphone valide").required(
                'Le champ téléphone est obligatoire'
            ),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values)
    },
})(MobileContact)

export const MobileNumberSubmitting = ({ img, config }: OwnProps) => {
    const { phoneNumber } = useAppSelector((state) => state.user.user)
    const user = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _updateUser = useCallback(
        async (user: Partial<User> & Partial<EditValues>) =>
            await dispatch(fetchUpdateUser(user)),
        [dispatch]
    )

    const _createAskForContactActivity = useCallback(async () => {
        await dispatch(
            createAskForContactActivity(CLICK.UPSELL_MENU_BE_CALLED)
        )
    }, [dispatch])

    const handleSubmit = async (values: FormValues) => {
        await _updateUser({
            ...user,
            phoneNumber: values.phoneNumber,
        })
        await _createAskForContactActivity()
    }
    return (
        <ControlledPhoneNumberForm
            img={img}
            config={config}
            initialValues={{ phoneNumber: phoneNumber }}
            onSubmit={handleSubmit}
        />
    )
}

const Content = ({
    img,
    text,
    onClick,
}: {
    img: string
    text: string
    onClick: () => void
}) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="space-around"
            flex={1}
            sx={{
                borderRadius: '10px',
                border: '1px solid #DDDDDD',
                height: '30vh',
                width: '18vw',
                textAlign: 'center',
                cursor: 'pointer',
                m: "0 1.5vw"
            }}
            onClick={onClick}
        >
            <img
                style={{ padding: 8, width: '100%', height: '70%' }}
                src={img}
            />
            <SubTitle width="100%" bold>{text}</SubTitle>
        </Grid>
    )
}

export const Default = ({ config, step }: OwnProps) => {
    const dispatch = useAppDispatch()
    const _setupSteps = useCallback(
        (contactConfig: ContactConfig) =>
            dispatch(setupContact({ config: contactConfig })),
        [dispatch]
    )

    return (
        <Grid container direction="column" height="100%" justifyContent="space-between">
            <DialogHeader
                img={KaryonLogo}
                title="Equipez-vous des fonctionnalités les plus avancées de KaryonFood"
                setOpen={() => {
                    config.handleReset()
                }}
            />
            <Grid textAlign="center">
                <SubTitle bold paddingTop="2vh">
                    Comment souhaitez-vous contacter votre conseiller KaryonFood
                    ?
                </SubTitle>
            </Grid>
            <Grid container justifyContent="space-around">
                {imgs.map((el, index) => (
                    <Content
                        img={el.img}
                        text={el.text}
                        onClick={() => {
                            _setupSteps({
                                steps: el.steps,
                            })
                            config.handleNext()
                        }
                        }
                        key={index}
                    />
                ))}
            </Grid>
            <DialogFooterOneAction
                text="Fermer"
                buttonFunc={() => {
                    config.handleReset()
                }}
            />
        </Grid >
    )
}

export const EmailContact = ({ config }: OwnProps) => {
    const dispatch = useAppDispatch()

    const _createAskForContactActivity = useCallback(async () => {
        await dispatch(
            createAskForContactActivity(CLICK.UPSELL_MENU_SEND_AN_EMAIL)
        )
    }, [dispatch])

    const handleSubmit = async () => {
        await _createAskForContactActivity()
    }

    return (
        <Grid container direction="column" height="100%" justifyContent="space-between">
            <DialogHeader
                img={KaryonLogo}
                title="Envoyer un email"
                setOpen={() => {
                    config.handleReset()
                }}
            />
            <SubTitle textAlign="center">
                Pour intégrer les données d’un distributeur supplémentaire, vous
                devez bénéficier d’un abonnement multi-distributeurs.<br />
                Discutons-en !
            </SubTitle>
            <Grid container>
                <Grid item flex={1} textAlign="center">
                    <img
                        style={{ width: '40%' }}
                        src={InterviewContact}
                    />
                </Grid>
                <Grid container flex={1} justifyContent="center">
                    <SubTitle textAlign="center" bold>
                        Nous nous tenons à votre disposition par mail,
                        écrivez-nous à l’adresse :
                    </SubTitle>
                    <Grid>
                        <Button sx={{ fontSize: { xs: '14px', md: '1.3vmin' }, color: DARK_GREY }} href={"mailto: coline@karyonfood.com"} onClick={handleSubmit} > coline@karyonfood.com</Button>
                    </Grid>
                </Grid>
            </Grid>
            <DialogFooter
                leftButtonFunc={() => {
                    config.handlePrevious()
                }}
                leftButtonText="Précédent"
                rightButtonFunc={() => {
                    handleSubmit()
                    config.handleReset()
                }}
                rightButtonText="Être contacté"
            />
        </Grid>
    )
}

export const Appointment = ({ config }: OwnProps) => {
    const dispatch = useAppDispatch()

    const _createAskForContactActivity = useCallback(async () => {
        await dispatch(
            createAskForContactActivity(CLICK.UPSELL_MENU_BOOK_MEETING)
        )
    }, [dispatch])

    const handleSubmit = async () => {
        await _createAskForContactActivity()
    }
    return (
        <Grid container direction="column" height="100%" justifyContent="space-between">
            <DialogHeader
                img={KaryonLogo}
                title="Planifier un rendez-vous"
                setOpen={() => {
                    config.handleReset()
                }}
            />
            <SubTitle textAlign="center">
                Pour intégrer les données d’un distributeur supplémentaire, vous
                devez bénéficier d’un abonnement multi-distributeurs.<br />
                Discutons-en !
            </SubTitle>
            <Grid container flexWrap="nowrap" height="60%">
                <Grid container flex={1} alignContent="center" justifyContent="center">
                    <img style={{ width: '40%' }} src={EmailContacts} />
                </Grid>
                <Grid item flex={1}>
                    <iframe
                        style={{
                            width: '100%',
                            height: '90%',
                            border: 'none',
                        }}
                        src={'https://meetings.hubspot.com/coline-bayor?embed=true'}
                    />
                </Grid>
            </Grid>
            <DialogFooter
                leftButtonFunc={() => {
                    config.handlePrevious()
                }}
                leftButtonText="Précédent"
                rightButtonFunc={() => {
                    handleSubmit()
                    config.handleReset()
                }}
                rightButtonText="Valider"
            />
        </Grid>
    )
}
