import React, { useCallback } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { Box, DialogContent, Grid } from '@mui/material'
import { array, object, string } from 'yup'
import {
    type StepConfig,
} from '../../../../redux/views/viewsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { EnvName, EnvStatus, EnvType, KaryonModules } from '../../../../redux/user/userTypes'
import { ErrorMessageForm, PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { DialogFooter } from '../../../../components/features/Dialog/BasicFooterDialog'
import { sendInvitation } from '../../../../redux/invitations/invitationsSlice'
import { CheckboxList } from '../../../../components/ui/CheckboxList'
import { listOfFSDistributors } from '../../Foodservice/types'
import { InvitationsData } from '../../../../redux/invitations/invitationsTypes'
import { getEnvNames } from './utilsAdminPage'

interface OwnProps {
    step: number
    config: StepConfig
}

interface FormValues {
    env: string[]
    modules: string[]
}

interface FormProps {
    step: number
    config: StepConfig
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

export enum ModulesFrenchNames {
    PROMO = "Module Promotion",
    CROSS = "Cross-distributeur",
    SELL_IN_RETAIL = "Sell-in"
}



export const getModulesNames = (list: KaryonModules[]) => {
    return list.map((module) => {
        switch (module) {
            case KaryonModules.PROMO:
                return ModulesFrenchNames.PROMO
            case KaryonModules.CROSS:
                return ModulesFrenchNames.CROSS
            case KaryonModules.SELL_IN_RETAIL:
                return ModulesFrenchNames.SELL_IN_RETAIL
        }
    }
    )
}

const PureSettingsForm = ({
    config,
    step,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const { env } = useAppSelector((state) => state.companies.company)
    const { companyModules } = useAppSelector((state) => state.companies.company)
    const envNames = getEnvNames(env)
    const modulesNames = getModulesNames(companyModules)

    const handleChangeEnvironnement = (envSelected) => {
        const isInclude = props.values.env.includes(envSelected)

        if (isInclude) {
            props.setFieldValue(
                'env',
                props.values.env.filter((el) => el !== envSelected)
            )
        } else {
            props.setFieldValue('env', [...props.values.env, envSelected])
        }


    }
    const handleChangeModules = (modulesSelected) => {
        const isInclude = props.values.modules.includes(modulesSelected)

        if (isInclude) {
            props.setFieldValue(
                'modules',
                props.values.modules.filter((el) => el !== modulesSelected)
            )
        } else {
            props.setFieldValue('modules', [...props.values.modules, modulesSelected])
        }
    }

    return (
        <Form style={{ width: '100%' }}>
            <DialogContent sx={{ height: '42vh' }}>
                <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>Sélectionnez les environnements et modules à partager</PrimaryText>
                <Grid container direction="row">
                    <CheckboxList titleList="Environnements disponibles" list={envNames} handleChange={handleChangeEnvironnement} checkedList={props.values.env} />
                    <CheckboxList titleList="Modules disponibles" list={modulesNames} handleChange={handleChangeModules} checkedList={props.values.modules} />
                </Grid>
                <Box mt="2vh">
                    <ErrorMessageForm message={props.errors.env} />
                </Box>
            </DialogContent>
            <DialogFooter
                leftButtonFunc={
                    config.handlePrevious
                }
                leftButtonText={'PRÉCÉDENT'}
                rightButtonFunc={async () => {
                    await props.submitForm()
                }}
                rightButtonText={
                    `ENVOYER L'INVITATION`
                }
            />
        </Form>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        env: props.initialValues.env,
        modules: props.initialValues.modules,
    }),
    validationSchema: () => {
        return object().shape({
            env: array()
                .of(string().required())
                .required().min(1, 'Sélectionnez au moins un environnement'),
            modules: array()
                .of(string())
                .optional()
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const InvitationEnvStep = ({ step, config }: OwnProps) => {
    const dispatch = useAppDispatch()
    const { data } = useAppSelector((state) => state.invitations)

    const _sendInvitation = useCallback(
        (data: InvitationsData) => dispatch(sendInvitation(data)),
        [dispatch]
    )
    const handleSubmit = (values: FormValues) => {

        const environnements = values.env.map((env) => {
            if ((Object.values(listOfFSDistributors) as string[]).includes(env)) {
                return {
                    name: env as EnvName,
                    type: EnvType.FOOD_SERVICE,
                    status: EnvStatus.ACTIVATED,

                }
            } else {
                return {
                    name: env as EnvName,
                    type: EnvType.RETAIl,
                    status: EnvStatus.ACTIVATED,
                }
            }
        })

        const modules = values.modules.map((module) => {
            switch (module) {
                case ModulesFrenchNames.CROSS:
                    return KaryonModules.CROSS
                case ModulesFrenchNames.PROMO:
                    return KaryonModules.PROMO
            }
        })

        _sendInvitation({
            ...data,
            env: environnements,
            modules: modules,

        })

        config.handleNext()

    }

    return (
        <ControlledSettingsForm
            step={step}
            config={config}
            onSubmit={handleSubmit}
            initialValues={{
                env: [],
                modules: [],
            }}
        />
    )
}

export default InvitationEnvStep
