import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React from "react"
import { GBHProductsHeadTabColumns } from "../../utils"
import { ProductsChartAndTwoList } from "../../../../../redux/fsProducts/fsProductsTypes"
import { useAppSelector } from "../../../../../redux/hooks"


export const ProductsPerSegment = () => {

    const products = useAppSelector((state) => state.fsProducts.products as unknown as ProductsChartAndTwoList)

    return (
        <Grid height="50vh" mb="1vh">
            <DataTableComponent
                rows={products.segmentsList}
                columns={GBHProductsHeadTabColumns("segment")}
                getRowId={(row) => row.segment}
                hasDrillDown={false}
            />
        </Grid>)


}