import { Grid } from '@mui/material'
import FsKpis, { ConfigKPIs } from './Dashboard/FsKpis'
import React, { useCallback, useState, useSyncExternalStore } from 'react'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import TopFlop from './Dashboard/TopFlop'
import FsSegmentation from './Dashboard/FsSegmentation'
import { HistogramFS } from './Dashboard/HistogramFS'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import {
    setDrillDownProducts,
    setDrillDownWarehouses,
} from '../../../../redux/fsProducts/fsProductsSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
    BLOCTYPE,
    Clients,
    EnvName,
    FsC10AndDistriboissonsKPIs,
    FsFFRKPIs,
    FsSodexoKPIs,
    FsTGMKPIs,
    RegionTableSegmentationC10AndDistriboissons,
} from '../../../../redux/user/userTypes'
import DashboardDataGrid from './Dashboard/DashboardDataGrid'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { LoadingWithoutText } from '../../../../components/features/screens/LoadingScreen'
import ChartBlock from '../../../../components/features/ChartBlock'
import LineChart from '../../../../components/features/foodService/dashboard/LineChart'
import { getResizeSnapshot, subscribeToResize } from '../../../../helpers/ExternalStore'
import { Units } from '../../../../redux/global/globalTypes'

export const DashboardFSPage = ({
    KPIsConfig,
    warehousesConfig,
    hasWarehouseDrilldown = true,
    drilldownTypeClientSegmentation,
    drilldownTypeWarehousesTop3,
    dashboardGridDataTitle,
    segmentationTableColumns,
    hasDrillDownOnSegmentation = true,
    segmentationLink
}: {
    KPIsConfig: ({
        KPIs,
        envName,
        unit
    }: {
        KPIs: FsTGMKPIs | FsFFRKPIs | FsSodexoKPIs | FsC10AndDistriboissonsKPIs
        envName: EnvName
        unit: Units
    }) => ConfigKPIs[]
    warehousesConfig: GridColDef[]
    hasWarehouseDrilldown?: boolean
    drilldownTypeClientSegmentation: DrillDownType
    drilldownTypeWarehousesTop3: DrillDownType
    dashboardGridDataTitle: string
    segmentationTableColumns?: GridColDef[]
    hasDrillDownOnSegmentation?: boolean
    segmentationLink: string
}) => {
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<any>(null)
    const { unit } = useAppSelector((state) => state.global)
    const [value, setValue] = useState(0)
    const dispatch = useAppDispatch()
    const { name } = useAppSelector((state) => state.global.env)
    const { segmentationDashboard, kpisDashboard, topFlopDashboard, lineChartDashboard, topTabDashboard, histogramDashboard } = useAppSelector((state) => state.global.fsDashboardData)
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const _setDrillDownWarehouses = useCallback(() => {
        dispatch(setDrillDownWarehouses([]))
    }, [dispatch])

    const _setDrillDownProducts = useCallback(() => {
        dispatch(setDrillDownProducts([]))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setDrillDownProducts()
        _setDrillDownWarehouses()
    }
    const dashboardGridData = useAppSelector((state) => state.global.fsDashboardData.topTabDashboard)

    const dashboardGridDataLink =
        name === EnvName.SODEXO
            ? `/food-service/${name}/clients`
            : `/food-service/${name}/depots`
    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <FsKpis KPIsConfig={KPIsConfig} kpisDashboard={kpisDashboard} />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '80vh' }}
            >
                <DrillDown
                    type={drilldownTypeClientSegmentation}
                    open={isOpen}
                    title={fieldSelected && fieldSelected.id}
                    value={value}
                    setValue={setValue}
                    row={fieldSelected}
                    handleClose={handleClose}
                />
                <Grid container sx={{ height: '40%' }} wrap="nowrap">
                    <ChartBlock
                        title={`Top/Flop par famille par mois (${unit})`}
                        link={`/food-service/${name}/produits`}
                    >
                        {topFlopDashboard ?
                            <TopFlop topFlopDashboard={topFlopDashboard} /> : <LoadingWithoutText />}
                    </ChartBlock>
                    <Grid item flex={3} pl="1vw">
                        <ChartBlock
                            title={`Vente par famille par mois (${unit})`}
                            link={`/food-service/${name}/produits`}
                        >
                            {topFlopDashboard && lineChartDashboard ? <LineChart topFlopDashboard={topFlopDashboard} lineChartDashboard={lineChartDashboard} /> : <LoadingWithoutText />}
                        </ChartBlock>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '62%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{
                            height: '100%', width: '14vw', '& .MuiDataGrid-columnHeaderTitleContainer': {
                                justifyContent: 'center !important',
                            },
                            ...segmentationDashboard && segmentationDashboard.type === BLOCTYPE.TABLE && {
                                '& div.MuiDataGrid-columnHeader:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1)':
                                {
                                    justifyContent: 'flex-start !important',
                                },
                                '& .MuiDataGrid-cell--textLeft:first-of-type': {
                                    justifyContent: 'flex-start !important',
                                },
                                '& .MuiGrid-container': {
                                    justifyContent: 'center',
                                },
                            }
                        }}
                    >
                        <ChartBlock link={segmentationLink} title={segmentationDashboard ? `${segmentationDashboard.title} (${unit})` : ""}>
                            {segmentationDashboard && (segmentationDashboard.type === BLOCTYPE.SEGMENTATION_WAREHOUSES || segmentationDashboard.type === BLOCTYPE.SEGMENTATION_UF) ?
                                (<FsSegmentation
                                    segmentationDashboard={segmentationDashboard.segmentationDashboard as Clients}
                                    setOpen={setOpen}
                                    setFieldSelected={setFieldSelected}
                                    hasDrillDownOnSegmentation={hasDrillDownOnSegmentation}
                                    segmentationLink={segmentationLink}
                                />) : segmentationDashboard && segmentationDashboard.type === BLOCTYPE.TABLE ?
                                    <DataGrid
                                        hideFooterSelectedRowCount={true}
                                        rows={segmentationDashboard.segmentationDashboard as RegionTableSegmentationC10AndDistriboissons[]}
                                        columns={segmentationTableColumns}
                                        getRowId={(row) => row.id}
                                        rowHeight={height * 0.035}
                                        hideFooter
                                    />
                                    : <LoadingWithoutText />}
                        </ChartBlock>
                    </Grid>
                    <Grid
                        container
                        flexWrap="nowrap"
                        direction="column"
                        flex={2}
                        pl="1vw"
                        sx={{ height: '100%' }}
                    >
                        <Grid item flex={1.5} mb="1vw" maxHeight="30vh">
                            <ChartBlock
                                title={`Volumes vendus par rapport à A-1 (${unit})`}
                                link={`/food-service/${name}/produits`
                                }
                            >
                                {histogramDashboard ? <HistogramFS /> : <LoadingWithoutText />}
                            </ChartBlock>
                        </Grid>
                        <Grid item flex={1.5} height="100%" maxHeight="30vh">
                            <ChartBlock
                                title={dashboardGridDataTitle}
                                link={dashboardGridDataLink}
                            >
                                {topTabDashboard ?
                                    <DashboardDataGrid
                                        columns={warehousesConfig}
                                        drilldown={hasWarehouseDrilldown}
                                        data={dashboardGridData}
                                        drilldownType={drilldownTypeWarehousesTop3}
                                        envName={name}
                                    /> : <LoadingWithoutText />}
                            </ChartBlock >
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
