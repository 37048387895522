import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import { C10AndDistriboissonsColumnsRegionsPage, C10AndDistriboissonsColumnsWarehousesPage } from '../utils'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { WarehousesLayout } from '../layout/WarehousesLayout'
import { WarehousesTwoList } from '../../../../redux/fsProducts/fsProductsTypes'
import { FsWarehousesPage2Tab } from '../Pages/FsWarehousesPage2Tab'

export const WarehousesPageC10AndDistriboissons = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const warehouses = useAppSelector((state) => state.fsProducts.warehouses as WarehousesTwoList)

    return (
        <PrivatePage title="Dépôts" attribute={0} period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.WAREHOUSES}
            />
            <WarehousesLayout>
                {warehouses &&
                    <FsWarehousesPage2Tab
                        columnsHeadTab={C10AndDistriboissonsColumnsRegionsPage()}
                        titleHeadTab={`Région commerciale (${warehouses.regionsList.length})`}
                        rowHeadTab={warehouses.regionsList}
                        columnsSecondTab={C10AndDistriboissonsColumnsWarehousesPage()}
                        titleSecondTab={`Liste des dépôts (${warehouses.warehousesList.length})`}
                        rowSecondTab={warehouses.warehousesList}
                        drillDownType={DrillDownType.WAREHOUSESC10DISTRIBOISSONS}
                    />}
            </WarehousesLayout>
        </PrivatePage>
    )
}
