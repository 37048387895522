import React, { useState } from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../../redux/hooks'
import { ProductsLayout } from '../../layout/ProductsLayout'
import { DetailTab } from '../../../../../components/ui/TabsForDataTableComponent'
import { Grid } from '@mui/material'
import { ProductsPerBrand } from './ProductsPerBrand'
import { ProductsPerSegment } from './ProductsPerSegment'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { ProductsChartAndTwoList } from '../../../../../redux/fsProducts/fsProductsTypes'
import { GridCellParams } from '@mui/x-data-grid'
import DrillDown from '../../../../../components/features/drillDowns/DrillDown'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { DataTableComponent } from '../../../DataTableComponent'
import { GBHProductsColumns } from '../../utils'


const dataGridConfig = () => {
    return [{
        id: "1",
        label: "Par marque",
        children: <ProductsPerBrand />
    },
    {
        id: "2",
        label: "Par segment",
        children: <ProductsPerSegment />
    }

    ]
}

export const ProductsPageGBH = () => {
    const [isDrilldownProductsOpen, setDrilldownProductsOpen] = useState(false)
    const [fieldProductsSelected, setFieldProductsSelected] =
        useState<GridCellParams<any>>()
    const [productsValue, setProductsValue] = useState(0)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const products = useAppSelector((state) => state.fsProducts.products as unknown as ProductsChartAndTwoList)
    const config = dataGridConfig()

    const handleCloseProducts = () => {
        setDrilldownProductsOpen(false)
    }
    return (
        <PrivatePage title="Références" attribute={0} period>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} />
            <ProductsLayout>
                <Grid
                    container
                    direction="column"
                    sx={{
                        '& .MuiDataGrid-row:hover #tendency': {
                            visibility: 'visible',
                        },
                    }}
                    p="1vw"
                    flexWrap="nowrap"
                >
                    {/* This graph is too loud to charge correctly and the page crash */}
                    {/* <Grid item>
                         {products &&
                            <ChartBlock title={"Volume vendu par référence"}>
                                <LineChartProduct chart={products.chart} period={products.period} legendDepth={1} />
                            </ChartBlock>
                        } 
                    </Grid>*/}
                    <ChartBlock
                        title={`Performances par marque - segment`}
                    >
                        {products && <DetailTab data={config}></DetailTab>
                        }
                    </ChartBlock>
                    <Grid mt="2vh">
                        <ChartBlock
                            title={`Performances par référence`}
                        >
                            {products && <Grid height="45vh" m="1.2vw">
                                <DrillDown
                                    type={DrillDownType.PRODUCTSGBH}
                                    open={isDrilldownProductsOpen}
                                    title={fieldProductsSelected && `${fieldProductsSelected.row.product}`}
                                    value={productsValue}
                                    setValue={setProductsValue}
                                    row={fieldProductsSelected}
                                    handleClose={handleCloseProducts}
                                />
                                <DataTableComponent
                                    type={DrillDownType.PRODUCTSGBH}
                                    setOpen={setDrilldownProductsOpen}
                                    setFieldSelected={setFieldProductsSelected}
                                    setValue={setProductsValue}
                                    rows={products.productsList}
                                    columns={GBHProductsColumns("brand")}
                                    getRowId={(row) => row.id}
                                />
                            </Grid>}
                        </ChartBlock>
                    </Grid>
                </Grid>
            </ProductsLayout>
        </PrivatePage>
    )
}
