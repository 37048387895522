import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { CustomersPageSodexo } from '../../../../../../pages/private/Foodservice/Sodexo/CustomersPage'
import { ProductsPageSodexo } from '../../../../../../pages/private/Foodservice/Sodexo/ProductsPage'
import { ViewPage } from '../../../../views/ViewPage'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'
import { PlaybookPromo } from '../../../../../../pages/private/Foodservice/PlaybookPromo'

export const SodexoRouter = (companyModules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageSodexo />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'produits/',
        element: <ProductsPageSodexo />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
    ...(companyModules.includes(KaryonModules.PROMO) ?
        [] :
        [{
            path: 'promoDemo/',
            element: <PlaybookPromo />,
            title: PagesName.PROMODEMO,
        },]),
]
