import React, { useEffect, useRef, useState } from 'react'
import Loading from '../screens/LoadingScreen'
import {
    ActiveFsFilters,
    FsViewFilters,
} from '../../../redux/filters/filtersTypes'
import {
    setActiveFsViewFilters,
    setFsViewFilters,
} from '../../../redux/filters/filtersSlice'
import { NoDataScreen } from '../screens/NoDataScreen'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { View } from '../../../redux/views/viewsTypes'
import { Grid } from '@mui/material'
import { getDashboardConfig } from './utilsView'
import { fetchDashboard } from '../../../redux/global/globalSlice'

export const ViewsLayout = ({
    view,
}: {
    view: View
}) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const { unit, isLoading, env } = useAppSelector((state) => state.global)
    const dashboard = useAppSelector((state) => state.global.fsDashboardData)
    const { min, max } = useAppSelector((state) => state.filters.activeFsFilters)
    const { activeFsViewFilters } = useAppSelector((state) => state.filters)
    const isFirstUpdate = useRef(true)
    const filters = view.filters as FsViewFilters


    useEffect(() => {

        dispatch(
            setFsViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                regions: filters.regions,
                categories: filters.categories,
                min: min,
                max: max
            })
        )
        dispatch(
            setActiveFsViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                clients: filters.clients,
                regions: filters.regions,
                categories: filters.categories,
                min: min,
                max: max
            })
        )
    }, [])

    useEffect(() => {
        if (!isFirstUpdate.current) {
            dispatch(fetchDashboard(filters as ActiveFsFilters)).then(() => {
                setIsFirstLoad(false)
                isFirstUpdate.current = false
            })

        }
    }, [filters, activeFsViewFilters])

    useEffect(() => {

        if (activeFsViewFilters.startDate && isFirstUpdate.current) {

            dispatch(fetchDashboard(activeFsViewFilters)).then(() => {
                setIsFirstLoad(false)
                isFirstUpdate.current = false
            })

        }
    }, [activeFsViewFilters, unit])

    if (isFirstLoad || isLoading) return <Loading />

    if (dashboard === null || (dashboard.topFlopDashboard && dashboard.topFlopDashboard.length === 0))
        return <NoDataScreen />

    return (<Grid>
        {!isLoading && getDashboardConfig(env.name, unit)}
    </Grid>)

}
