import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React from "react"
import { GBHSuppliersHeadTabColumns } from "../../utils"
import { useAppSelector } from "../../../../../redux/hooks"

export const SuppliersPerBrand = () => {
    const { suppliers } = useAppSelector((state) => state.fsProducts)
    const { brand } = useAppSelector((state) => state.user.user)

    return (
        <Grid height="50vh">
            <DataTableComponent
                rows={suppliers.brandsList}
                columns={GBHSuppliersHeadTabColumns("brand", brand && brand)}
                getRowId={(row) => row.brand}
                hasDrillDown={false}
            />
        </Grid >
    )
}