import React, { useEffect, useState } from 'react'
import { Grid, Tooltip, Box } from '@mui/material'
import PrimaryKpiCard, {
    type KpiCardType,
    KpiType,
    MockedPrimaryCard,
} from '../../../../../components/features/PrimaryKpiCard'
import { useAppSelector } from '../../../../../redux/hooks'
import { Units } from '../../../../../redux/global/globalTypes'
import {
    EnvName,
    FsC10AndDistriboissonsKPIs,
    FsCercleVertKPIs,
    FsEpiSaveursKPIs,
    FsFFRKPIs,
    FsGBHKPIs,
    FsMetroKPIs,
    FsSodexoKPIs,
    FsTGMKPIs,
    FsTGMPlusKPIs,
} from '../../../../../redux/user/userTypes'

interface KpiData extends KpiCardType {
    id: number
    description?: string
}

export interface ConfigKPIs {
    id: number
    name: string
    units: Units
    units2?: Units
    description: string
    number: number
    number2?: number
    difference: number
    difference2?: number
    route?: string
    evolution: number
    evolution2?: number
    type?: KpiType
    evolutionUnit?: Units
    evolution2Unit?: Units
    unitType?: Units
    unitType2?: Units
}

const FsKpis = ({
    KPIsConfig,
    kpisDashboard
}: {
    KPIsConfig: ({
        KPIs,
        envName,
        unit
    }: {
        KPIs: FsTGMKPIs | FsFFRKPIs | FsGBHKPIs | FsC10AndDistriboissonsKPIs | FsEpiSaveursKPIs | FsCercleVertKPIs | FsMetroKPIs | FsSodexoKPIs | FsTGMPlusKPIs
        envName: EnvName
        unit: Units
    }) => ConfigKPIs[]
    kpisDashboard: FsTGMKPIs | FsFFRKPIs | FsGBHKPIs | FsC10AndDistriboissonsKPIs | FsEpiSaveursKPIs | FsCercleVertKPIs | FsMetroKPIs | FsSodexoKPIs | FsTGMPlusKPIs
}) => {
    const [enhancedKpis, setKpis] = useState<KpiData[] | null>([])
    const { unit } = useAppSelector((state) => state.global)
    const { env } = useAppSelector((state) => state.global)

    useEffect(() => {
        if (kpisDashboard) {
            setKpis(
                KPIsConfig({
                    KPIs: kpisDashboard,
                    envName: env.name,
                    unit
                })
            )
        }
    }, [kpisDashboard, unit])

    const kpisCounter = enhancedKpis && enhancedKpis.length

    return (
        <Grid container justifyContent="space-between">
            {enhancedKpis.length > 0
                ? enhancedKpis.map((kpi) => (
                    <Tooltip key={kpi.id} title={kpi.description}>
                        <Box
                            sx={{
                                flex: 1,
                                maxWidth:
                                    kpisCounter === 4
                                        ? '24%'
                                        : kpisCounter === 2
                                            ? '49.5%'
                                            : '32.5%',
                            }}
                        >
                            <PrimaryKpiCard
                                difference={kpi.difference ? kpi.difference : 0}
                                name={kpi.name}
                                number={kpi.number ? kpi.number : 0}
                                units={kpi.units}
                                route={kpi.route}
                                evolution={kpi.evolution ? kpi.evolution : 0}
                                difference2={kpi.difference2 ? kpi.difference2 : 0}
                                evolution2={kpi.evolution2 ? kpi.evolution2 : 0}
                                number2={kpi.number2 ? kpi.number2 : 0}
                                units2={kpi.units2}
                                type={kpi.type}
                                evolutionUnit={kpi.evolutionUnit}
                                evolution2Unit={kpi.evolution2Unit}
                                unitType={kpi.unitType}
                                unitType2={kpi.unitType2}
                            />
                        </Box>
                    </Tooltip>
                ))
                :
                Array(1)
                    .fill('a')
                    .map((item, index) => (
                        <MockedPrimaryCard key={`${item}-${index}`} />
                    ))}
        </Grid>
    )
}

export default FsKpis
