import { Grid } from "@mui/material"
import { DataTableComponent } from "../../../DataTableComponent"
import React from "react"
import { GBHProductsHeadTabColumns } from "../../utils"
import { useAppSelector } from "../../../../../redux/hooks"
import { ProductsChartAndTwoList } from "../../../../../redux/fsProducts/fsProductsTypes"

export const ProductsPerBrand = () => {
    const products = useAppSelector((state) => state.fsProducts.products as unknown as ProductsChartAndTwoList)

    return (
        <Grid height="50vh" mb="1vh">
            <DataTableComponent
                rows={products.brandsList}
                columns={GBHProductsHeadTabColumns("brand")}
                getRowId={(row) => row.brand}
                hasDrillDown={false}
            />
        </Grid>
    )
}