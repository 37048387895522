import React from "react"
import { BLACK } from "../../../ui/Colors";
import { PrimaryText, SubTitle } from "../../../ui/Text";
import { Step } from "react-joyride";
import distributorsExample from '../../../../assets/distributorsExample.png'

export const addDistributorSteps = [{
    content: (<>  <img src={`${distributorsExample}`}></img><PrimaryText color={BLACK}><span style={{ fontWeight: "bold" }}> Le saviez-vous ? </span>Vous pouvez analyser les données de plusieurs distributeurs dans votre espace KaryonFood. </PrimaryText></>),
    locale: { skip: <></>, next: "Suivant", last: "Suivant", back: "Retour" },
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: 'center',
    target: 'body',
    title: <SubTitle> Bonjour !</SubTitle>,
    data: {},
},
{
    content: <PrimaryText color={BLACK}><span style={{ fontWeight: "bold" }}>Cliquez ici</span> pour découvrir tous les espaces distributeurs disponibles.</PrimaryText>,
    locale: { next: "Suivant", },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
        options: {
            zIndex: 10000,
        },
    },
    target: "#env-selector",
    data: {},
},
{
    content: <PrimaryText color={BLACK}>Ajoutez les données d’autres distributeurs via le menu distributeur !</PrimaryText>,
    locale: { next: "Suivant", last: "Terminer", back: "Retour" },
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    spotlightClicks: false,
    placement: 'right',
    styles: {
        options: {
            zIndex: 10000,
        },
    },
    target: "#ghost-distributors",
    data: {
    },
}] as Step[]