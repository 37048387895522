import React, { useState } from "react";
import { EnvName, EnvStatus, EnvType, KaryonModules } from "../../../redux/user/userTypes";
import { PrimaryText } from "../../ui/Text";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { distributorImages } from "../../../helpers/distributorsList";
import { Separator } from "../../ui/Separator";
import { BLACK } from "../../ui/Colors";
import { DialogData } from "../../../pages/private/Global/Import/Import";
import ContactDialog from "../../../pages/private/Global/Import/dialog/contact/ContactDialog";

interface OwnsProps {
    title: string,
    type: EnvType,
    handleEnvSelection: (e: React.MouseEvent, name: EnvName, type: EnvType, isModule: boolean, isActivated: boolean) => void,
    items: { name: EnvName }[] | { name: string, isActivated: boolean }[],
    isModule: boolean
}

const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}


enum TooltipModuleNotActivated {
    CROSS = "Vous avez plusieurs distributeurs ? Activez le CROSS pour une vue unifiée : idéal pour suivre l’implantation de vos références et leurs types d’utilisateurs agrégés.",
    PROMO = "Maitrisez vos actions promotionnelles : stockage, paniers moyens, acquisition d’utilisateurs finaux, coûts… accédez à des analyses complexes en toute simplicité.",
    SELL_IN = "Avec ce croisement sorties caisses et données de facturation, accédez au meilleur des 2 mondes ! Optimisez vos ventes par chef de secteur, client, types de marché…"
}

const getLogoWithTheRightSize = (isModule: boolean, name: EnvName | KaryonModules) => {
    if (isModule || name === EnvName.SELLIN) {
        return (<img
            style={{
                width: '1.5vw',
                paddingRight: '2vw',
            }}
            alt="Logo du distributeur"
            src={name.includes("Promo") ? distributorImages['Promo'] : distributorImages[name]}
        />)
    } else {
        return (
            <img
                style={{
                    width: '3vw',
                    paddingRight: '1vw',
                }}
                alt="Logo du distributeur"
                src={distributorImages[name]}
            />
        )
    }
}

const getTooltip = (name: any) => {
    switch (name) {
        case KaryonModules.CROSS:
            return TooltipModuleNotActivated.CROSS
        case KaryonModules.PROMO:
            return TooltipModuleNotActivated.PROMO
        case KaryonModules.SELL_IN_RETAIL:
            return TooltipModuleNotActivated.SELL_IN
    }
}

export const RenderSection = ({ title, handleEnvSelection, items, type, isModule }: OwnsProps) => {
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)

    const openModaleContact = () => {
        setDialogContactData({
            isOpen: true,
            envName: EnvName.MYDISTRIB,
            status: EnvStatus.INACTIVATED,
        })
    }

    return (
        <Grid>
            <PrimaryText style={{
                color: BLACK,
                padding: '1vh',
                fontWeight: 'bold',
                margin: '1vh',
                width: "100%"
            }}>{title}</PrimaryText>
            <List dense sx={{ padding: 0, ml: "2vw" }}>
                {items.map(({ name, isActivated = true }) => (
                    isActivated ? (
                        <ListItem
                            key={name}
                            id={`${name}-distributor`}
                            sx={{
                                cursor: 'pointer',
                                p: '1vh',
                                margin: '1vh'
                            }}
                            onClick={(e) => { handleEnvSelection(e, name, type, isModule, isActivated) }}
                        >
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                {getLogoWithTheRightSize(isModule, name)}
                            </ListItemIcon>
                            <ListItemText
                                primary={name}
                                sx={{
                                    '& .MuiTypography-root': {
                                        fontSize: '1.6vmin !important',
                                        color: BLACK
                                    },
                                }}
                            />
                        </ListItem>)
                        :
                        (<Tooltip title={getTooltip(name)} >
                            <ListItem
                                key={name}
                                id={`${name}-distributor`}
                                sx={{
                                    cursor: 'pointer',
                                    p: '1vh',
                                    margin: '1vh'
                                }}
                                disabled
                                onClick={(e) => { if ([KaryonModules.CROSS, KaryonModules.SELL_IN_RETAIL].includes(name)) { openModaleContact() } else { handleEnvSelection(e, name, type, isModule, isActivated) } }}
                            >
                                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                    {getLogoWithTheRightSize(isModule, name)}
                                </ListItemIcon>
                                <ListItemText
                                    primary={name}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '1.6vmin !important',
                                            color: BLACK
                                        },
                                    }}
                                />
                                <ContactDialog
                                    isOpen={dialogContactData.isOpen}
                                    handleClose={() => {
                                        setDialogContactData(resetState)
                                    }}
                                />
                            </ListItem>
                        </Tooltip>
                        )
                ))}
            </List>
            <Grid container justifyContent="center">
                <Separator width={"70%"} />
            </Grid>
        </Grid>
    )
}