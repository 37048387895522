import { Grid } from '@mui/material'
import React from 'react'
import { GREY } from '../../components/ui/Colors'
import { PrimaryText } from '../../components/ui/Text'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import Navigation, {
    type NavigationData,
} from '../../components/features/navigation/Navigation'
import fsIcon from '../../assets/icon/fsIcon.svg'

const links = (): NavigationData[] => [
    {
        id: 0,
        label: 'Import',
        link: '/parametres/import',
        icon: <img style={{ width: '2.5vw', height: '2.5vh' }} src={fsIcon} />,
    },
    {
        id: 1,
        label: 'Profil',
        link: '/parametres/profil',
        icon: <PermIdentityIcon />,
    },
]

const PreferencesNavigation = () => {
    return (
        <Grid sx={{ width: 'fill-available' }}>
            <Grid container wrap="nowrap" alignItems="center" mb="2vh">
                <PrimaryText width="inherit" noWrap>
                    Préférences
                </PrimaryText>
                <hr
                    style={{
                        margin: '0 1vh',
                        width: '100%',
                        height: '1px',
                        border: 'none',
                        background: GREY,
                    }}
                />
            </Grid>
            <Navigation data={links()} />
        </Grid >
    )
}

export default PreferencesNavigation
