import React, { useState } from 'react'
import { Grid } from '@mui/material'
import ChartBlock from '../../../../components/features/ChartBlock'
import { DataTableComponent } from '../../DataTableComponent'
import { GridCellParams, GridColDef } from '@mui/x-data-grid'
import { DrillDownType } from '../../../../components/features/drillDowns/ProductsDrillDownContent'
import DrillDown from '../../../../components/features/drillDowns/DrillDown'
import { RegionItemWithLiterAndUVC, RegionItemWithShare, WarehouseItemWithLiterAndUVC, WarehouseItemWithShare } from '../../../../redux/fsProducts/fsProductsTypes'

interface OwnProps {
    columnsHeadTab: GridColDef[],
    columnsSecondTab: GridColDef[],
    titleHeadTab: string
    titleSecondTab: string
    drillDownType: DrillDownType
    rowHeadTab: RegionItemWithShare[] | RegionItemWithLiterAndUVC[]
    rowSecondTab: WarehouseItemWithShare[] | WarehouseItemWithLiterAndUVC[]
}


export const FsWarehousesPage2Tab = ({ columnsHeadTab, columnsSecondTab, titleHeadTab, titleSecondTab, drillDownType, rowHeadTab, rowSecondTab }: OwnProps) => {

    const [isDrilldownOpen, setDrilldownOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<GridCellParams<any>>()
    const [value, setValue] = useState(0)

    const handleClose = () => {
        setDrilldownOpen(false)
    }

    return (
        <Grid
            container
            direction="column"
            sx={{
                height: '90vh',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
            flexWrap="nowrap"
        >
            <Grid height="45vh">
                <ChartBlock
                    title={titleHeadTab}
                >
                    <Grid height={'100%'} m="1.2vw">
                        <DataTableComponent
                            rows={rowHeadTab}
                            columns={columnsHeadTab}
                            hasDrillDown={false}
                            getRowId={(row) => row.id}
                        />
                    </Grid>

                </ChartBlock>
            </Grid>
            <Grid height="45vh" mt="2vh">
                <ChartBlock
                    title={titleSecondTab}
                >
                    <Grid height={'100%'} m="1.2vw">
                        <DrillDown
                            type={drillDownType}
                            open={isDrilldownOpen}
                            title={fieldSelected && `${fieldSelected.row.warehouse}`}
                            value={value}
                            setValue={setValue}
                            row={fieldSelected}
                            handleClose={handleClose}
                        />
                        <DataTableComponent
                            type={drillDownType}
                            setOpen={setDrilldownOpen}
                            setFieldSelected={setFieldSelected}
                            setValue={setValue}
                            rows={rowSecondTab}
                            columns={columnsSecondTab}
                            getRowId={(row) => row.id}
                        />
                    </Grid>
                </ChartBlock>
            </Grid>
        </Grid>

    )
}
