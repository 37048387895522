import React from 'react'
import { DialogHeader } from '../Dialog/BasicDialog'
import { Dialog, DialogContent as DialogContentWrapper } from '@mui/material'
import {
    ConnectorType,
    EnvStatus,
    type EnvType,
} from '../../../redux/user/userTypes'
import {
    DialogFooter,
    DialogFooterOneAction,
} from '../Dialog/BasicFooterDialog'
import ManualActivated from '../../../pages/private/Global/Import/dialog/ManualActivated'
import AutoActivated from '../../../pages/private/Global/Import/dialog/AutoActivated'
import LoadInit from '../../../pages/private/Global/Import/dialog/LoadInit'
import AutoInactivated from '../../../pages/private/Global/Import/dialog/AutoInactivated'
import { DialogData } from '../../../pages/private/Global/Import/Import'
import ManualInactivated from '../../../pages/private/Global/Import/dialog/ManualInactivated'

interface ImportDialogProps {
    handleClose: (e) => void
    dialog: DialogData
    type: EnvType.RETAIl | EnvType.FOOD_SERVICE
    img: string
    connectorType: ConnectorType
    lastImportDate?: string
    goToDashboard?: () => void

}

interface DialogContentProps {
    status: EnvStatus
    connectorType: ConnectorType
    type: EnvType
    distributor: string
    handleClose: (e) => void
    lastImportDate: string
    goToDashboard: () => void
}

const DialogContent = ({
    status,
    distributor,
    type,
    lastImportDate,
    handleClose,
    goToDashboard,
    connectorType,
}: DialogContentProps) => {
    switch (status) {
        case EnvStatus.ON_LOADING_INIT:
            return <LoadInit />
        case EnvStatus.INACTIVATED:
            if (connectorType === ConnectorType.AUTOMATED) {
                return (
                    <AutoInactivated
                        goToDashboard={goToDashboard}
                        handleClose={handleClose}
                        distrib={distributor}
                    />
                )
            } else {
                return (
                    <ManualInactivated
                        distributor={distributor}
                        type={type}
                        connectorType={connectorType}
                    />
                )
            }
        case EnvStatus.ACTIVATED:
            if (connectorType === ConnectorType.AUTOMATED) {
                return (
                    <AutoActivated
                        type={type}
                        connectorType={connectorType}
                        lastImportDate={lastImportDate}
                        distrib={distributor}
                    />
                )
            } else {
                return (
                    <ManualActivated
                        connectorType={connectorType}
                        distributor={distributor}
                        type={type}
                        status={status}
                        lastImportDate={lastImportDate}
                    />
                )
            }
        default: {
            return (
                <ManualActivated
                    connectorType={connectorType}
                    distributor={distributor}
                    type={type}
                    status={status}
                    lastImportDate={lastImportDate}
                />
            )
        }
    }
}

const FooterContent = ({ status, handleClose, goToDashboard }) => {
    switch (status) {
        case EnvStatus.ACTIVATED:
        case EnvStatus.OUTDATED:
        case EnvStatus.ON_LOADING:
            return (
                <DialogFooter
                    rightButtonFunc={(e) => {
                        e.stopPropagation()
                        goToDashboard()
                    }}
                    rightButtonText={'Tableau de bord'}
                    leftButtonFunc={(e) => {
                        e.stopPropagation()
                        handleClose(e)
                    }}
                    leftButtonText="Fermer"
                />
            )
        case EnvStatus.INACTIVATED:
            return (
                <DialogFooter
                    rightButtonFunc={(e) => {
                        e.stopPropagation()
                        handleClose(e)
                    }}
                    rightButtonText={'Valider'}
                    leftButtonFunc={(e) => {
                        e.stopPropagation()
                        handleClose(e)
                    }}
                    leftButtonText="Fermer"
                />
            )
        default:
            return (
                <DialogFooterOneAction buttonFunc={handleClose} text="Fermer" />
            )
    }
}

const ImportDialog = ({
    dialog,
    handleClose,
    type,
    img,
    connectorType,
    lastImportDate,
    goToDashboard,
}: ImportDialogProps) => {
    return (
        <Dialog
            maxWidth={false}
            onClose={(e: any) => {
                e.stopPropagation()
                handleClose(e)
            }}
            open={dialog.isOpen}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    padding: '1em',
                    width: 'auto',
                    height: 'auto',
                },
            }}
        >
            <DialogHeader
                img={img}
                title="Importer des données"
                setOpen={(e) => {
                    handleClose(e)
                }}
            />
            <DialogContentWrapper>
                <DialogContent
                    connectorType={connectorType}
                    goToDashboard={goToDashboard}
                    handleClose={handleClose}
                    status={dialog.status}
                    distributor={dialog.envName}
                    type={type}
                    lastImportDate={lastImportDate}
                />
            </DialogContentWrapper>
        </Dialog>
    )
}

export default ImportDialog
