import { Card, Grid, Box } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { BLACK, DARK_BLUE, LIGHT_BLUE, LIGHT_MOCK, PRIMARY, WHITE } from '../ui/Colors'
import { PrimaryText, SubTitle } from '../ui/Text'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface OwnProps {
    title: string
    children: JSX.Element
    link?: string
    titleHasHover?: boolean
    help?: string | JSX.Element
}

const HEADER_HEIGHT = '0.4'

const LinkButton = ({ route }: { route: string }) => {
    const navigate = useNavigate()

    return (
        <Box
            id='knowMoreButton'
            onClick={() => {
                navigate(route)
            }}
            sx={{
                cursor: 'pointer',
                background: LIGHT_BLUE,
                borderRadius: '5px',
                padding: '0.2vh 1vw',
                position: 'absolute',
                top: '1vh',
                right: '0.8vw',
            }}
        >
            <PrimaryText color={DARK_BLUE}>En savoir plus</PrimaryText>
        </Box>
    )
}

interface HelpProps {
    hasLink: boolean,
    children: string | JSX.Element
}

const CloseBtn = ({ setHelp }: { setHelp: React.Dispatch<React.SetStateAction<boolean>> }) => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            onClick={(e) => {
                e.stopPropagation();
                setHelp(false)
            }}
            sx={{
                borderRadius: "45px",
                background: "white",
                width: "1.5vw",
                height: "1.5vw",
                position: "absolute",
                right: '0.5vw',
                top: '1vh'
            }}
        >
            <CloseIcon
                sx={{
                    cursor: 'pointer',
                    width: '2vw',
                    height: '2vh',
                }}
            />
        </Grid>
    )
}

const Help = ({ hasLink, children }: HelpProps) => {
    const [isHelp, setHelp] = useState(false)

    const position = {
        right: hasLink ? "9vw" : "1vw",
        top: "1.2vh"
    }

    return (
        <Grid
            container
            onClick={() => { setHelp(true) }}
            sx={{
                cursor: "pointer",
                position: "absolute",
                right: position.right,
                top: position.top,
                height: "20px",
                width: "20px",
                zIndex: "99999",
                transition: "all 0.3s ease-out",
                ...(isHelp ? {
                    right: 0,
                    top: 0,
                    width: "100%",
                    height: "100% !important"
                } : null)
            }}>
            {!isHelp && <HelpOutlineIcon />}
            {isHelp && <Grid container sx={{
                position: "relative",
                background: PRIMARY,
                justifyContent: "center",
                alignItems: "center"
            }}>
                {typeof children === "string" ? <PrimaryText color={WHITE}>{children}</PrimaryText> : children}
                <CloseBtn setHelp={setHelp} />
            </Grid>}
        </Grid >
    )
}

const ChartBlock = ({
    title,
    children,
    link,
    titleHasHover = true,
    help
}: OwnProps) => {
    const [isHover, setHover] = useState<boolean>(false)

    return (
        <Card
            sx={{
                height: '100%',
                position: "relative"
            }}
            onMouseOver={() => {
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}
        >
            {isHover && help && <Help hasLink={!!link}>{help}</Help>}
            <Grid
                container
                position="relative"
                justifyContent="space-between !important"
                alignItems="center"
                wrap="nowrap"
                sx={{
                    borderBottom: `1px solid ${LIGHT_MOCK}`,
                    '& span': { fontSize: '1.2rem' },
                    padding: { xs: '10px', md: `${HEADER_HEIGHT}vw` },
                    height: '4.5vh',
                }}
            >
                <SubTitle
                    color={BLACK}
                    noWrap
                    width={
                        isHover && titleHasHover
                            ? 'calc(100% - 8.9vw)'
                            : 'initial'
                    }
                >
                    {title}
                </SubTitle>
                {isHover && link && <LinkButton route={link} />}
            </Grid>
            <Grid
                container
                direction="column"
                sx={{ height: `calc(100% - 4.5vh)` }}
            >
                {children}
            </Grid>
        </Card >
    )
}

export default ChartBlock
