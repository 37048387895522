import React, { useCallback } from 'react'
import { Grid } from '@mui/material'
import FileUploader, {
    type DropStatus,
} from '../../../../components/features/import/FileUploader'
import { type HistoricList } from '../../../../redux/imports/importsTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { handleAddImport } from '../../../../redux/imports/importsSlice'
import { type EnvType } from '../../../../redux/user/userTypes'
import { DataType } from './Import'

interface ImportDialogProps {
    actualEnv: EnvType
    actualDistrib: string
    onUpload?: () => void,
    dataType?: DataType
}

const ImportUpload = ({
    actualEnv,
    actualDistrib,
    onUpload,
    dataType
}: ImportDialogProps) => {
    const dispatch = useAppDispatch()
    const _addImport = useCallback(
        async (historic: Partial<HistoricList>) =>
            await dispatch(handleAddImport(historic)),
        [dispatch]
    )
    const { _id, firstname, lastname } = useAppSelector(
        (state) => state.user.user
    )

    const handleUpload = (file: any, alert: DropStatus) => {
        if (file && alert.msg) {
            _addImport({
                filename: file.name,
                username: `${firstname} ${lastname}`,
                message: alert.msg,
                userId: _id,
                envType: actualEnv,
                distributorName: actualDistrib,
                dataType: dataType
            })

            if (onUpload && alert.type === 'success') {
                onUpload()
            }
        }
    }

    return (
        <Grid>
            <FileUploader dataType={dataType} onFileUpload={handleUpload} />
        </Grid>
    )
}

export default ImportUpload
