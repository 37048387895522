import React, { useState, useCallback, useEffect } from 'react'
import { Grid } from '@mui/material'
import ImportHistoric from './ImportHistoric'
import ContactDialog from './dialog/contact/ContactDialog'
import { fetchImports, fetchImportsStatus } from '../../../../redux/imports/importsSlice'
import { distributorImages } from '../../../../helpers/distributorsList'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { setGlobalEnv } from '../../../../redux/global/globalSlice'
import { useNavigate } from 'react-router-dom'
import {
    ConnectorType,
    Env,
    EnvName,
    EnvStatus,
    EnvType,
} from '../../../../redux/user/userTypes'
import AddDistributorDialog from '../../../../components/features/addDistributor/AddDistributorDialog'
import ImportDialog from '../../../../components/features/import/ImportDialog'
import { Historic } from '../../../../redux/imports/importsTypes'
import ChartBlock from '../../../../components/features/ChartBlock'
import { PrimaryText } from '../../../../components/ui/Text'
import ImportUpload from './ImportUpload'
import { BLACK } from '../../../../components/ui/Colors'
import { monthsAsObject } from '../../../../helpers/months'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard"
import HeatMapTable from '../../../../components/features/import/HeatMap'

export enum ImportSatus {
    INTEGRATED = "success",
    IN_PROGRESS = "in-progress",
    MISSED = "missed"
}

interface OwnProps {
    envList: Env[]
    type: EnvType.RETAIl | EnvType.FOOD_SERVICE
    historicImports: Historic
}

export interface DialogData {
    isOpen: boolean
    envName: EnvName
    status: EnvStatus
    connectorType?: ConnectorType
}

const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}

export enum DataType {
    SELL_OUT = "sell-out",
    SELL_IN = "sell-in",
    OTHER = "other"
}

interface ImportContentProps { title: string, distrib: string, envType: EnvType, message: string, dataType: DataType }

const ImporterContent = ({ title, distrib, envType, message, dataType }: ImportContentProps) => {
    return (
        <ChartBlock title={title}>
            <Grid >
                <PrimaryText padding="2vh" color={BLACK}>
                    {message}
                </PrimaryText>
                <Grid padding="4vw">
                    <ImportUpload
                        actualDistrib={distrib}
                        actualEnv={envType}
                        dataType={dataType}
                    />
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

const Import = ({ envList, type, historicImports }: OwnProps) => {
    const navigate = useNavigate()
    const { name } = useAppSelector((state) => state.global.env)
    const { plan } = useAppSelector((state) => state.companies.company)
    const [dialogData, setDialogData] = useState<DialogData>(resetState)
    const [dialogDataAdd, setDialogDataAdd] = useState<DialogData>(resetState)
    const { _id: userId } = useAppSelector((state) => state.user.user)
    const { importStatus } = useAppSelector((state) => state.imports)
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    let period = []

    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )
    const _getImports = useCallback(
        async (type: EnvType.FOOD_SERVICE | EnvType.RETAIl) => {
            await dispatch(fetchImports(type))
        },
        [dispatch]
    )

    for (let i = 1; i <= month; i++) {
        period.push(`${year}-${i < 10 && "0"}${i}`)
    }

    for (let i = 12; i > month; i--) {
        period.unshift(`${year - 1}-${i < 10 ? "0" : ""}${i}`)
    }

    const _getStatusImports = useCallback(
        async (type: EnvType.FOOD_SERVICE | EnvType.RETAIl) => {
            await dispatch(fetchImportsStatus(type, period))
        },
        [dispatch]
    )

    useEffect(() => {
        const getHistory = async (
            type: EnvType.FOOD_SERVICE | EnvType.RETAIl
        ) => {
            _getImports(type)
            _getStatusImports(type)
        }

        if (userId) {
            getHistory(type)
        }
    }, [_getImports, _getStatusImports, type, userId])

    const missedImports = importStatus && importStatus.map((el) => {
        return {
            distrib: el.distrib,
            statusPerMonth: Object.fromEntries(Object.entries(el.statusPerMonth).
                filter(([key, val]) => val === ImportSatus.MISSED))
        }
    });

    const listOfMissedImports = missedImports && missedImports.map((el) => Object.keys(el.statusPerMonth).map((status, index) => (
        `${el.distrib}_` + monthsAsObject[status.slice(5, 7)] + " " + status.slice(0, 4)))).flat()

    const dataTransformed = importStatus && importStatus.map((el) => {
        return {
            name: el.distrib,
            status: Object.values(el.statusPerMonth)
        }
    })

    console.log(listOfMissedImports)

    return (
        <Grid container direction="column" wrap="nowrap" p="1vw">
            <Grid
                justifyContent="flex-start"
                mb="1vw"
            >
                <ChartBlock title="">
                    <Grid container wrap='nowrap' mb="2vh">
                        <HeatMapTable data={dataTransformed} />
                        <Grid ml="10vw" maxHeight="15vh" overflow="auto" width="15vw">
                            <Grid container wrap='nowrap' mb="2vh" justifyContent="space-between">
                                <PrimaryText bold color={BLACK}>Fichiers à importer</PrimaryText>
                                <ContentCopyIcon onClick={() => copy(listOfMissedImports.toString())} sx={{ mr: 2, cursor: "pointer" }} />
                            </Grid>
                            {listOfMissedImports && listOfMissedImports.map((el) =>
                                <PrimaryText color={BLACK}>
                                    {el}
                                </PrimaryText>
                            )}
                        </Grid>
                    </Grid>
                </ChartBlock>
            </Grid>
            <Grid
                container
                wrap="nowrap"
                justifyContent="flex-start"
                minHeight="55%"
                mb="1vw"
            >
                <Grid item width="100%" height="100%">
                    <ImporterContent dataType={DataType.SELL_OUT} distrib={name} envType={type} title='Données sell-out'
                        message="Déposez ici vos fichiers sell-out bruts mensuels et trimestriels." />
                </Grid>
            </Grid>
            <Grid
                container
                gap={3}
                wrap="nowrap"
                justifyContent="flex-start"
                mb="1vw"
                width="100%"
            >
                <Grid flex={2}>
                    <ImporterContent dataType={DataType.OTHER} distrib={name} envType={type} title='Catégories, segmentations, nomenclatures'
                        message="Déposez ici vos fichiers comprenant vos catégorisations produit et vos segmentations régionales." />
                </Grid>
                <Grid flex={2}>
                    <ImporterContent dataType={DataType.SELL_IN} distrib={name} envType={type} title='Module promo : Données sell-in'
                        message="Déposez ici vos fichiers cde données sell-in (données internes de facturation)." />
                </Grid>
            </Grid>
            <Grid item height="45vh">
                <ImportHistoric list={historicImports.list} />
            </Grid>
            <ContactDialog
                isOpen={dialogContactData.isOpen}
                handleClose={() => {
                    setDialogContactData(resetState)
                }}
            />
            <ImportDialog
                img={distributorImages[dialogData.envName]}
                dialog={dialogData}
                type={type}
                connectorType={dialogData.connectorType}
                handleClose={() => {
                    setDialogData({
                        ...resetState,
                        connectorType: dialogData.connectorType,
                    })
                }}
            />
            <AddDistributorDialog
                type={type}
                handleClose={() => {
                    setDialogDataAdd(resetState)
                }}
                isOpen={dialogDataAdd.isOpen}
            />
        </Grid>
    )
}
export default Import
