import React from "react";
import { DashboardFSPage } from "../../../pages/private/Foodservice/Pages/DashboardFSPage";
import { EnvName } from "../../../redux/user/userTypes";
import { C10AndDistriboissonsColumnsDashboard, C10AndDistriboissonsColumnsSegmentationTable, EpiSaveursAndPassionFroidColumnsDashboard, FFRColumnsDashboard, GBHColumnsDashboard, SodexoColumnsDashboard, TransgourmetColumnsDashboard } from "../../../pages/private/Foodservice/utils";
import { DrillDownType } from "../drillDowns/ProductsDrillDownContent";
import { Units } from "../../../redux/global/globalTypes";
import { KPIsC10AndDistriboissonsConfig, KPIsCercleVertConfig, KPIsEpiSaveursConfig, KPIsFFRConfig, KPIsGBHConfig, KPIsMetroConfig, KPIsPassionFroidConfig, KPIsSodexoConfig, KPIsTGMConfig } from "../../../pages/private/Foodservice/Pages/Dashboard/KPIsUtils";
import { DashboardGBHPage } from "../../../pages/private/Foodservice/Pages/DashboardGBHPage";


export const getDashboardConfig = (envName: EnvName, unit: Units) => {

    switch (envName) {
        case EnvName.FRANCE_FRAIS:

            return (<DashboardFSPage
                KPIsConfig={KPIsFFRConfig}
                warehousesConfig={FFRColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTS}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
                segmentationLink={`/food-service/${envName}/clients`}
            />)
        case EnvName.METRO:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsMetroConfig}
                warehousesConfig={FFRColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTSMETRO}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESMETRO}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)

        case EnvName.SODEXO:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsSodexoConfig}
                warehousesConfig={SodexoColumnsDashboard(unit)}
                hasWarehouseDrilldown={false}
                drilldownTypeClientSegmentation={
                    DrillDownType.CLIENTSSODEXO
                }
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                dashboardGridDataTitle={`Volume livré par type de marché (${unit})`}
            />)

        case EnvName.CERCLEVERT:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsCercleVertConfig}
                warehousesConfig={FFRColumnsDashboard(unit)}
                hasWarehouseDrilldown={true}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTSCERCLEVERT}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)

        case EnvName.EPISAVEURS:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsEpiSaveursConfig}
                warehousesConfig={EpiSaveursAndPassionFroidColumnsDashboard(
                    unit
                )}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTS}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)

        case EnvName.PASSIONFROID:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsPassionFroidConfig}
                warehousesConfig={EpiSaveursAndPassionFroidColumnsDashboard(
                    unit
                )}
                drilldownTypeClientSegmentation={
                    DrillDownType.CLIENTSPF
                }
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESPF}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)

        case EnvName.TRANSGOURMET:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsTGMConfig}
                warehousesConfig={TransgourmetColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={
                    DrillDownType.CLIENTSTGM
                }
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESTGM}
                dashboardGridDataTitle={`Top 3 des dépôts en part du chiffre d’affaires`}
            />)
        case EnvName.GBH:
            return (<DashboardGBHPage
                segmentationLink={`/food-service/${envName}/fournisseurs`}
                KPIsConfig={KPIsGBHConfig}
                warehousesConfig={GBHColumnsDashboard()}
                dashboardGridDataTitle={`Top 5 - Fournisseurs GBH (${Units.uvc})`}
            />)
        case EnvName.TRANSGOURMETPLUS:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsFFRConfig}
                warehousesConfig={FFRColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTS}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)
        case EnvName.C10:
        case EnvName.DISTRIBOISSONS:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/depots`}
                KPIsConfig={KPIsC10AndDistriboissonsConfig}
                warehousesConfig={C10AndDistriboissonsColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={DrillDownType.WAREHOUSESC10DISTRIBOISSONS}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSESC10DISTRIBOISSONS}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
                segmentationTableColumns={C10AndDistriboissonsColumnsSegmentationTable(unit)}
                hasDrillDownOnSegmentation={false}
            />)
        default:
            return (<DashboardFSPage
                segmentationLink={`/food-service/${envName}/clients`}
                KPIsConfig={KPIsFFRConfig}
                warehousesConfig={FFRColumnsDashboard(unit)}
                drilldownTypeClientSegmentation={DrillDownType.CLIENTS}
                drilldownTypeWarehousesTop3={DrillDownType.WAREHOUSES}
                dashboardGridDataTitle={`Top 3 des dépôts par croissance (${unit})`}
            />)
    }
}