import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client, { setHeaders } from '../../api/config'
import { ActiveFsCrossFilters, ActiveFsFilters, ActiveRetailFilters } from '../filters/filtersTypes'
import { AppThunk } from '../store'
import {
    Clients,
    Env,
    EnvName,
    EnvStatus,
    EnvType,
    FsCrossDashboard,
    FsDashboard,
    FsFFRKPIs,
    FsGBHKPIs,
    FsTGMKPIs,
    Histogram,
    LineChartDashboard,
    Products,
    RetailDashboard,
    SegmentationDashboard,
    TopTabDashboard,
} from '../user/userTypes'
import { GlobalState, Units } from './globalTypes'
import {
    getActiveFilters,
    getURLForFilteredDashboard,
    isDashboardFiltered,
} from './utils'

const initialState: GlobalState = {
    env: {
        name: EnvName.MYDISTRIB,
        type: EnvType.SETTING,
        status: EnvStatus.INACTIVATED,
    },
    fsDashboardData: {
        topFlopDashboard: null,
        lineChartDashboard: null,
        kpisDashboard: null,
        segmentationDashboard: null,
        histogramDashboard: null,
        topTabDashboard: null,
        regions: null,
    },
    fsCrossDashboardData: null,
    retailDashboardData: null,
    period: null,
    isLoading: false,
    error: null,
    unit: Units.kg,
    isFiltered: false,
}

const GlobalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        initGlobalInfos: (state, action: PayloadAction<GlobalState>) => {
            state = action.payload
        },
        setGlobalEnv: (state, action: PayloadAction<Env>) => {
            state.env = action.payload
        },
        resetDashboard: (state) => {
            state.fsDashboardData = {
                topFlopDashboard: null,
                lineChartDashboard: null,
                kpisDashboard: null,
                segmentationDashboard: null,
                histogramDashboard: null,
                topTabDashboard: null,
                regions: null,
            }
            state.retailDashboardData = null
        },
        setFsDashboard: (state, action: PayloadAction<FsDashboard>) => {
            state.fsDashboardData = action.payload
        },
        setFsCrossDashboard: (
            state,
            action: PayloadAction<FsCrossDashboard>
        ) => {
            state.fsCrossDashboardData = action.payload
        },
        setRetailDashboard: (state, action: PayloadAction<RetailDashboard>) => {
            state.retailDashboardData = action.payload
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
        },
        setUnit: (state, action: PayloadAction<Units>) => {
            state.unit = action.payload
        },
        setIsFiltered: (state, action: PayloadAction<boolean>) => {
            state.isFiltered = action.payload
        },
        setSegmentationDashboard: (state, action: PayloadAction<SegmentationDashboard>) => {
            state.fsDashboardData.segmentationDashboard = action.payload
        },
        setKpisDashboard: (state, action: PayloadAction<FsFFRKPIs | FsTGMKPIs | FsGBHKPIs>) => {
            state.fsDashboardData.kpisDashboard = action.payload
        },
        setTopFlopDashboard: (state, action: PayloadAction<Products[]>) => {
            state.fsDashboardData.topFlopDashboard = action.payload
        },
        setLineChartDashboard: (state, action: PayloadAction<LineChartDashboard>) => {
            state.fsDashboardData.lineChartDashboard = action.payload
        },
        setHistogramDashboard: (state, action: PayloadAction<Histogram>) => {
            state.fsDashboardData.histogramDashboard = action.payload
        },
        setTopTabDashboard: (state, action: PayloadAction<TopTabDashboard[]>) => {
            state.fsDashboardData.topTabDashboard = action.payload
        },
        resetSegmentationDashboard: (state) => {
            state.fsDashboardData.segmentationDashboard = null
        },
        resetKpisDashboard: (state) => {
            state.fsDashboardData.kpisDashboard = null
        },
        resetTopFlopDashboard: (state) => {
            state.fsDashboardData.topFlopDashboard = null
        },
        resetLineChartDashboard: (state) => {
            state.fsDashboardData.lineChartDashboard = null
        },
        resetHistogramDashboard: (state) => {
            state.fsDashboardData.histogramDashboard = null
        },
        resetTopTabDashboard: (state) => {
            state.fsDashboardData.topTabDashboard = null
        },
    },
})

export const {
    initGlobalInfos,
    setGlobalEnv,
    resetDashboard,
    setFsDashboard,
    setRetailDashboard,
    setLoading,
    setError,
    setUnit,
    setIsFiltered,
    setFsCrossDashboard,
    setSegmentationDashboard,
    setKpisDashboard,
    setTopFlopDashboard,
    setLineChartDashboard,
    setHistogramDashboard,
    setTopTabDashboard,
    resetSegmentationDashboard,
    resetKpisDashboard,
    resetTopFlopDashboard,
    resetLineChartDashboard,
    resetHistogramDashboard,
    resetTopTabDashboard,
} = GlobalSlice.actions
export default GlobalSlice.reducer

export const fetchDashboard = (filters): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        const envType = getState().global.env.type

        dispatch(fetchKpisDashboard(filters, false))
        dispatch(fetchSegmentationDashboard(filters, false))
        dispatch(fetchTopFLopDashboard(filters, false))
        dispatch(fetchLineChartDashboard(filters, false))
        dispatch(fetchTopTabDashboard(filters, false))
        envType !== EnvType.FOOD_SERVICE_CROSS ? dispatch(fetchHistogramDashboard(filters, false)) : null
    }

export const fetchFilteredDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name

            if (localStorage.getItem("multiAccountCompanyId")) { setHeaders(null, localStorage.getItem("multiAccountCompanyId")) }
            else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters
            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }
            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const url = getURLForFilteredDashboard(envType, distributor, unit)
            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }

            dispatch(setLoading(true))
            try {
                const dashboard = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (envType === EnvType.FOOD_SERVICE_CROSS) {
                    dispatch(
                        setFsCrossDashboard({
                            productsChart:
                                dashboard.productsChart.formatedProductsChart,
                            legendChart: dashboard.productsChart.legend,
                            productsTopFlop: dashboard.productsTopFlop,
                            KPIs: dashboard.kpis,
                            clients: dashboard.clients,
                            distributorsList: dashboard.distributorsList,
                        })
                    )
                }

                if (envType === EnvType.RETAIl) {
                    dispatch(
                        setRetailDashboard({
                            kpis: dashboard.kpis[0],
                            products: dashboard.products,
                            productsChart: dashboard.productsChart,
                            productsSplit: dashboard.productsSplit[0],
                            stores: dashboard.stores,
                            geoStores: dashboard.geoStores,
                        })
                    )
                }
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }

export const fetchSegmentationDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const company = getState().user.user.brand
            dispatch(resetSegmentationDashboard())

            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit
            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters

            const url = envType === EnvType.FOOD_SERVICE_CROSS ? `/dashboard/segmentation?distrib=cross&unit=${unit}` : `/dashboard/segmentation?distrib=${distributor}&unit=${unit}`
            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }


            try {
                const segmentation = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }
                dispatch(setSegmentationDashboard(segmentation))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }

export const fetchKpisDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const company = getState().user.user.brand
            const distributor = getState().global.env.name
            dispatch(resetKpisDashboard())

            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit

            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters

            const url = envType === EnvType.FOOD_SERVICE_CROSS ? `/dashboard/kpis?distrib=cross&unit=${unit}` : `/dashboard/kpis?distrib=${distributor}&unit=${unit}`
            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }


            try {
                const kpis = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }

                dispatch(setKpisDashboard(kpis.dashboardKpis))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }

export const fetchTopFLopDashboard =
    (
        filters: ActiveFsFilters | ActiveFsCrossFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const envType = getState().global.env.type
            const unit = getState().global.unit
            const company = getState().user.user.brand
            dispatch(resetTopFlopDashboard())


            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const url = envType === EnvType.FOOD_SERVICE_CROSS ? `/dashboard/topFlop?distrib=cross&unit=${unit}` : `/dashboard/topFlop?distrib=${distributor}&unit=${unit}`

            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters

            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }

            try {
                const topFlop = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }

                dispatch(setTopFlopDashboard(topFlop.topFlopDashboard))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }

export const fetchLineChartDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const company = getState().user.user.brand
            const distributor = getState().global.env.name
            dispatch(resetLineChartDashboard())

            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit

            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters

            const url = envType === EnvType.FOOD_SERVICE_CROSS ? `/dashboard/lineChart?distrib=cross&unit=${unit}` : `/dashboard/lineChart?distrib=${distributor}&unit=${unit}`

            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }


            try {
                const lineChart = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }

                dispatch(setLineChartDashboard(lineChart.lineChartDashboard))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }

export const fetchHistogramDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const company = getState().user.user.brand
            dispatch(resetHistogramDashboard())

            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit
            const initialDataFilters = getState().filters.fsFilters

            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }


            try {
                const histogram = await client
                    .post(`/dashboard/histogram?distrib=${distributor}&unit=${unit}`, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }

                dispatch(setHistogramDashboard(histogram.histogramDashboard))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }


export const fetchTopTabDashboard =
    (
        filters: ActiveFsFilters | ActiveRetailFilters,
        onView: boolean
    ): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            dispatch(resetTopTabDashboard())
            const company = getState().user.user.brand
            if (localStorage.getItem("multiAccountCompanyId")) {
                setHeaders(null, localStorage.getItem("multiAccountCompanyId"))

            } else { { setHeaders(null, null, distributor) } }

            const envType = getState().global.env.type
            const unit = getState().global.unit

            const initialDataFilters =
                envType === EnvType.FOOD_SERVICE_CROSS ?
                    getState().filters.fsCrossFilters :
                    envType === EnvType.FOOD_SERVICE
                        ? getState().filters.fsFilters
                        : getState().filters.retailFilters

            const url = envType === EnvType.FOOD_SERVICE_CROSS ? `/dashboard/topTab?distrib=cross&unit=${unit}` : `/dashboard/topTab?distrib=${distributor}&unit=${unit}`

            const filtersName = getActiveFilters(envType, onView)
            const activeFilters = getState().filters[filtersName]

            if (envType === EnvType.SETTING) {
                dispatch(setLoading(false))

                return console.log('ERROR')
            }

            const isDashboardFilter = isDashboardFiltered(
                initialDataFilters,
                activeFilters
            )

            const payload = {
                ...{ data: filters },
                ...{ isDashboardFilter },
            }


            try {
                const topTab = await client
                    .post(url, payload)
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                if (distributor === EnvName.GBH) {
                    dispatch(setUnit(Units.uvc))
                } else if (distributor === EnvName.C10 || distributor === EnvName.DISTRIBOISSONS || company === "La Martiniquaise") {
                    dispatch(setUnit(Units.liter))
                } else {
                    dispatch(setUnit(Units.kg))
                }

                dispatch(setTopTabDashboard(topTab.topTabDashboard))
            } catch (e) {
                console.log('ERROR :', e)
            }
        }