import React from 'react'
import Label from './DoughnutLabel'
import CheckboxLabels from './MyCheckBoxUpdate'
import { Grid } from '@mui/material'
import { useAppSelector } from '../../../../../redux/hooks'
import { SquareColor } from '../../../../ui/ColorSquare'
import { EnvType } from '../../../../../redux/user/userTypes'
import { LinkButton } from '../../../../ui/Button'

export const labels = [
    {
        backgroundColor: [
            '#3A4D87',
            '#D4F7A6',
            '#EF9BFD',
            '#F7EAA6',
            '#F7B0A6',
            '#FACE5D',
            '#A17D5B',
            '#E76161',
            '#C9DBB2',
            '#B71375',
        ],
    },
    {
        backgroundColor: [
            '#B9C7F9',
            '#32A9FF',
            '#9EF2ED',
            '#0F52FF',
            '#A17D5B',
            '#192C70',
        ],
    },
]

const sortFamily = (data: number[], labels: string, total: number) => {
    const list = []
    data.forEach((elem, index) =>
        list.push({ base: (data[index] / total) * 100, second: labels[index] })
    )

    list.sort((a, b) => {
        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    const newBase = []
    const newSecond = []
    list.forEach((elem, index) => {
        newBase[index] = list[index].base
        newSecond[index] = list[index].second
    })

    return {
        data: newBase,
        labels: newSecond,
    }
}

const DoughnutLabels = ({
    setFieldSelected,
    setOpen,
    clients,
    hasDrillDownOnSegmentation = true,
    segmentationLink
}: {
    setFieldSelected?: (field: any) => void
    setOpen?: (open: boolean) => void
    clients: any
    hasDrillDownOnSegmentation?: boolean
    segmentationLink: string
}) => {
    const { env } = useAppSelector((state) => state.global)

    const sortedValues = sortFamily(
        clients.data,
        clients.labels as any,
        clients.data.reduce((acc, curr) => acc + curr, 0)
    )

    return (
        <Grid container sx={{ maxWidth: '90%' }}>
            <Grid container direction="column" key={0}>
                <CheckboxLabels label={env.type !== "food-service-cross" ? 'Type' : 'Top dépôts'}
                />
                <Grid container mt={1}>
                    {sortedValues.labels.map((label, labelIndex) => {
                        if (labelIndex > 9) return undefined

                        const index = clients.labels.findIndex(
                            (el) => el === label
                        )

                        return (
                            <Label
                                key={label}
                                clickHandler={() => {

                                    if (env.type === EnvType.FOOD_SERVICE_CROSS || window.location.href.includes("promo") || !hasDrillDownOnSegmentation) { return null }

                                    setOpen(true)
                                    setFieldSelected({
                                        id: label,
                                        row: {
                                            volume: clients.data && clients.data[index],
                                            volumeLastYear: clients.dataLastYear && clients.dataLastYear[index],
                                            clients: clients.clients && clients.clients[index],
                                            products: clients.products && clients.products[index],
                                            productsLastYear:
                                                clients.productsLastYear && clients.productsLastYear[index],
                                            differenceWeight:
                                                clients.differenceWeight && clients.differenceWeight[index],
                                            differenceClients:
                                                clients.differenceClients && clients.differenceClients[index],
                                            differenceProducts:
                                                clients.differenceClients && clients.differenceClients[index],
                                            warehouses: clients.warehouses && clients.warehouses[index],
                                            warehousesLastYear:
                                                clients.warehousesLastYear && clients.warehousesLastYear[index],
                                            VMM: clients.VMM && clients.VMM[index],
                                            VMMLastYear: clients.VMMLastYear && clients.VMMLastYear[index],
                                            differenceVMM: clients.differenceVMM && clients.differenceVMM[index],
                                            ca: clients.data && clients.data[index],
                                            caLastYear: clients.dataLastYear && clients.dataLastYear[index],
                                            differencCa: clients.differencePercentageCA && clients.differencePercentageCA[index],
                                            percentageCA: clients.percentageCA && clients.percentageCA[index],
                                            percentageCALastYear: clients.percentageCALastYear && clients.percentageCALastYear[index],
                                            differencePercentageCA: clients.differencePercentageCA && clients.differencePercentageCA[index],
                                            differenceProductsFamily: clients.differenceProductsFamily && clients.differenceProductsFamily[index],
                                            productsFamily: clients.productsFamily && clients.productsFamily[index],
                                        }

                                    })
                                }}
                                label={
                                    label +
                                    ' : ' +
                                    Math.round(sortedValues.data[labelIndex]) +
                                    '%'
                                }
                                color={labels[0].backgroundColor[labelIndex]}
                                size={SquareColor.SM}
                            />
                        )
                    })}
                    {sortedValues.labels.length > 10 && env.type !== "food-service-cross" ? (
                        <LinkButton to={segmentationLink}>Voir +</LinkButton>
                    ) : undefined}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DoughnutLabels
