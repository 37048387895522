import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getFSRows,
    getWarehousesColumns,
    getWarehousesNotDistributorsColumns,
} from './setupDrillDown'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownWarehouses,
    fetchDrillDownWarehousesNotDistributors,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownType } from './ProductsDrillDownContent'
import { WarehouseNotDistributorGBHItem } from '../../../redux/fsProducts/fsProductsTypes'

export const ProductsGBHDrillDownContent = ({
    row,
    isFirstVisit,
    value,
    handleChange,
    type,
}: DrillDownContentProps) => {
    const [isLoadingWarehouses, setLoadingWarehouses] = useState(true)
    const [isLoadingWarehousesNotDistributors, setLoadingWarehousesNotDistributors] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownWarehouses, drillDownWarehousesNotDistributors } = useAppSelector(
        (state) => state.fsProducts
    )
    const { unit } = useAppSelector((state) => state.global)

    const warehousesLength = drillDownWarehouses
        ? drillDownWarehouses.length
        : ''
    const warehousesNotDistributorsLength = drillDownWarehousesNotDistributors
        ? drillDownWarehousesNotDistributors.length
        : ''

    const _fetchDrillDownWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehouses(filters))
        },
        [dispatch]
    )

    const _fetchDrillDownWarehousesNotDistributors = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehousesNotDistributors(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
        suppliers: activeFsFilters.suppliers,
        universes: activeFsFilters.universes
    }

    useEffect(() => {
        const fetchDataWarehouses = async () => {
            await _fetchDrillDownWarehouses(filters)
        }
        const fetchDataWarehousesNotDistributors = async () => {
            await _fetchDrillDownWarehousesNotDistributors(filters)
        }

        fetchDataWarehouses().then(() => setLoadingWarehouses(false))
        fetchDataWarehousesNotDistributors().then(() => setLoadingWarehousesNotDistributors(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Dépôts (${warehousesLength})`,
                `Dépôts non distributeurs (${warehousesNotDistributorsLength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownWarehouses && !isLoadingWarehouses && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownWarehouses)}
                        columns={getWarehousesColumns(type)}
                        message={
                            messageToDisplay({
                                type: DrillDownType.WAREHOUSES,
                                value: row.row.warehouses,
                                oldValue: row.row.warehousesLastYear,
                            })
                        }
                    />
                )}
                {isLoadingWarehouses && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownWarehousesNotDistributors && !isLoadingWarehousesNotDistributors && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownWarehousesNotDistributors as WarehouseNotDistributorGBHItem[])}
                        columns={getWarehousesNotDistributorsColumns(type, unit)}
                        message={`Psssst, ci-dessous la précieuse liste des dépôts non distributeurs de la référence ${row.row.product}`}
                    />
                )}
                {isLoadingWarehousesNotDistributors && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
