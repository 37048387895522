import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import DoughnutLabels, { labels } from './DoughnutLabels'
import { Grid, Tooltip as MUITooltip, } from '@mui/material'
import { useAppSelector } from '../../../../../redux/hooks'
import { PrimaryText } from '../../../../ui/Text'
import { BLACK } from '../../../../ui/Colors'
import { EnvName, EnvType, SegmentationGBH } from '../../../../../redux/user/userTypes'
import { Units } from '../../../../../redux/global/globalTypes'
import DashboardDataGrid from '../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardDataGrid'
import { DrillDownType } from '../../../drillDowns/ProductsDrillDownContent'
import { GBHRegionsColumnsDashboard } from '../../../../../pages/private/Foodservice/utils'
import { NoDataPlaceholder } from '../utils/NoDataPlaceHolder'

ChartJS.register(ArcElement, Tooltip, Legend)

const isEmptyDoughtnut = (data: number[]) => {
    const isAllEmpty = data.every((el) => el === 0)
    return isAllEmpty
}

export const addArrow = (number: string) => {
    if (number === '0') {
        return ''
    } else if (number > '0') {
        return '↑'
    } else {
        return '↓'
    }
}

const getEmptyDoughtnutTooltip = (
    clientsLastYear: string[],
    dataLastYear: number[]
) => {
    const formatedTooltip = clientsLastYear.map((client, index) => {
        return (
            <Grid key={index}>
                {client}: {dataLastYear[index]} client(s)
            </Grid>
        )
    })
    return formatedTooltip
}

const sortClients = (
    data: number[],
    labels: string[],
    clients?: number[],
    evolutionClients?: number[],
    evolution?: number[],
    caData?: number[],
    evolutionCa?: number[],
    shareVolume?: number[],
    shareCa?: number[],
    evolutionShareVolume?: number[],
    evolutionShareCa?: number[],
    evolutionVolume?: number[],
    evolutionUnitVolume?: number[],
    unitVolume?: number[]
) => {
    const list = []

    data.forEach((elem, index) =>
        list.push({
            base: data[index],
            second: labels[index],
            third: clients ? clients[index] : 0,
            fourth: evolutionClients ? evolutionClients[index] : 0,
            five: evolution ? evolution[index] : 0,
            sixth: caData ? caData[index] : 0,
            seventh: evolutionCa ? evolutionCa[index] : 0,
            eigthh: shareVolume ? shareVolume[index] : 0,
            nineth: shareCa ? shareCa[index] : 0,
            tenth: evolutionShareVolume ? evolutionShareVolume[index] : 0,
            eleventh: evolutionShareCa ? evolutionShareCa[index] : 0,
            twelve: evolutionVolume ? evolutionVolume[index] : 0,
            thirteen: evolutionUnitVolume ? evolutionUnitVolume[index] : 0,
            fourteen: unitVolume ? unitVolume[index] : 0
        })
    )

    list.sort((a, b) => {
        return a.base < b.base ? 1 : a.base === b.base ? 0 : -1
    })

    const newData = []
    const newLabels = []
    const newClients = []
    const newEvolutionClients = []
    const newEvolutions = []
    const newCaData = []
    const nenEvolutionCa = []
    const newShareVolume = []
    const newShareCa = []
    const newEvoltuionShareVolume = []
    const newEvolutionShareCa = []
    const newEvolutionVolume = []
    const newEvolutionUnitVolume = []
    const newUnitVolume = []

    list.forEach((elem, index) => {
        newData[index] = list[index].base
        newLabels[index] = list[index].second
        newClients[index] = list[index].third
        newEvolutionClients[index] = list[index].fourth
        newEvolutions[index] = list[index].five
        newCaData[index] = list[index].sixth
        nenEvolutionCa[index] = list[index].seventh
        newShareVolume[index] = list[index].eigthh
        newShareCa[index] = list[index].nineth
        newEvoltuionShareVolume[index] = list[index].tenth
        newEvolutionShareCa[index] = list[index].eleventh
        newEvolutionVolume[index] = list[index].twelve
        newEvolutionUnitVolume[index] = list[index].thirteen
        newUnitVolume[index] = list[index].fourteen
    })

    return {
        data: newData,
        labels: newLabels,
        clients: newClients,
        evolutionClients: newEvolutionClients,
        evolutions: newEvolutions,
        caData: newCaData,
        evolutionCa: nenEvolutionCa,
        shareVolume: newShareVolume,
        shareCa: newShareCa,
        evolutionShareVolume: newEvoltuionShareVolume,
        evolutionShareCa: newEvolutionShareCa,
        evolutionVolume: newEvolutionVolume,
        evolutionUnitVolume: newEvolutionUnitVolume,
        unitVolume: newUnitVolume
    }
}

const DoughnutChart = ({
    setFieldSelected,
    setOpen,
    withLegend = true,
    hasDrillDownOnSegmentation = true,
    segmentationLink
}: {
    setFieldSelected: (field: any) => void
    setOpen: (open: boolean) => void
    withLegend?: boolean
    hasDrillDownOnSegmentation?: boolean
    segmentationLink?: string
}) => {
    const [isLabels, setIsLabels] = useState<boolean>(false)
    const chartRef = React.useRef()
    const { env } = useAppSelector((state) => state.global)
    const { unit } = useAppSelector((state) => state.global)
    let clients = null
    let regionsGBH = []
    if (window.location.href.includes("promo")) {
        clients = useAppSelector((state) => state.promotions.performance.chart)
    } else if (env.name === EnvName.GBH) {
        const segmentationGBH = useAppSelector((state) => state.global.fsDashboardData.segmentationDashboard.segmentationDashboard as SegmentationGBH)

        clients = segmentationGBH.segmentation
        const { regions } = useAppSelector((state) => state.global.fsDashboardData.segmentationDashboard.segmentationDashboard as SegmentationGBH)
        regionsGBH = regions
    } else {
        clients = useAppSelector((state) => state.global.fsDashboardData.segmentationDashboard.segmentationDashboard)
    }

    if (!clients || clients.length === 0) {
        return <NoDataPlaceholder />
    }

    useEffect(() => {
        setIsLabels(true)
    }, [chartRef])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e, elements) => {
            if (env.type === EnvType.FOOD_SERVICE_CROSS || window.location.href.includes("promo") || !hasDrillDownOnSegmentation) { return null }
            const index = clients.data.findIndex(
                (el) => el === elements[0].element.$context.parsed
            )

            setOpen(true)
            setFieldSelected({
                id: clients.labels[index],
                row: {
                    volume: clients.data[index],
                    volumeLastYear:
                        clients.dataLastYear[index],
                    clients: clients.clients[index],
                    products: clients.products[index],
                    productsLastYear:
                        clients.productsLastYear[index],
                    differenceWeight:
                        clients.differenceWeight[index],
                    differenceClients:
                        clients.differenceClients[
                        index
                        ],
                    differenceProducts:
                        clients.differenceClients[
                        index
                        ],
                    warehouses:
                        clients.warehouses[index],
                    warehousesLastYear:
                        clients.warehousesLastYear[
                        index
                        ],
                    VMM: clients.VMM[index],
                    VMMLastYear:
                        clients.VMMLastYear[index],
                    differenceVMM:
                        clients.differenceVMM[index],
                },
            })
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const labelIndex = model.dataIndex

                        const total = model.dataset.data.reduce(
                            (acc, curr) => acc + curr,
                            0
                        )

                        return `${sortedClients.labels[labelIndex]
                            } : ${Math.round((model.parsed / total) * 100)}%`
                    },
                    footer: (model: any) => {
                        const labelIndex = model[0].dataIndex
                        const sells = Math.round(model[0].parsed)
                        const clientsNumber = sortedClients.clients[labelIndex]
                        const unitVolume = Math.round(
                            sortedClients.unitVolume[labelIndex]
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const clientsEvolution =
                            sortedClients.evolutionClients[labelIndex]

                        const ca = Math.round(sortedClients.caData[labelIndex]).toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const shareCa = Math.round(sortedClients.shareCa[labelIndex])

                        const shareVolume = Math.round(sortedClients.shareVolume[labelIndex])

                        const evolutionCa =
                            Math.round(sortedClients.evolutionCa[labelIndex])

                        const evolutionShareCa =
                            Math.round(sortedClients.evolutionShareCa[labelIndex])

                        const evolutionShareVolume =
                            Math.round(sortedClients.evolutionShareVolume[labelIndex])

                        const sellsEvolution = Math.round(
                            sortedClients.evolution[labelIndex]
                        )

                        const evolutionVolume = Math.round(
                            sortedClients.evolutionVolume[labelIndex]
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const evolutionUnitVolume = Math.round(
                            sortedClients.evolutionUnitVolume[labelIndex]
                        ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedVolume = `Volume : ${sells
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${unit}`

                        const printedUnitVolume = `Volume : ${unitVolume
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${Units.uvc}`
                        const printedClient = `\nNombre d'utilisateurs finaux: ${clientsNumber
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}` // Add space in number : 1000 => 1 000

                        const printedSellsEvolution = sellsEvolution
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedCaEvolution = evolutionCa
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedShareCaEvolution = evolutionShareCa
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedShareVolumeEvolution = evolutionShareVolume
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        const printedClientsEvolution = Math.round(
                            clientsEvolution
                        )
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')



                        const getTooltip = (distrib: string) => {
                            switch (distrib) {
                                case EnvName.FRANCE_FRAIS:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} % ${printedClient}\nÉvolution utilisateurs finaux : ${addArrow(
                                            printedClientsEvolution
                                        )} ${printedClientsEvolution} %`
                                    )
                                case EnvName.TRANSGOURMET:
                                case EnvName.PASSIONFROID:
                                case EnvName.SODEXO:
                                case EnvName.CERCLEVERT:
                                case EnvName.EPISAVEURS:
                                case EnvName.FOOD_SERVICE_CROSS:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} %`
                                    )
                                case EnvName.GBH:
                                    return (
                                        `Volume vendu en ${Units.ca} : ${ca} - Évolution  : ${addArrow(
                                            printedCaEvolution
                                        )} ${printedCaEvolution} % \nPDM en ${Units.ca} : ${shareCa} - Évolution : ${addArrow(
                                            printedShareCaEvolution
                                        )} ${printedShareCaEvolution}\n-\nVolume vendu en ${Units.uvc} : ${sells.toString()
                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} - Évolution  : ${addArrow(
                                                printedSellsEvolution
                                            )} ${printedSellsEvolution} %\nPDM en ${Units.uvc} : ${shareVolume} - Évolution : ${addArrow(
                                                printedShareVolumeEvolution
                                            )} ${printedShareVolumeEvolution}`
                                    )
                                case EnvName.METRO:
                                    return `CA: ${sells.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} ${Units.euro}`

                                case EnvName.C10:
                                case EnvName.DISTRIBOISSONS:
                                    return `\nEn L\n   ${printedVolume} \n   Évolution vs. A-1: ${evolutionVolume}%\n\n---\n\nEn UVC\n   ${printedUnitVolume} \n    Évolution vs. A-1: ${evolutionUnitVolume}%\n`


                                default:
                                    return (
                                        printedVolume +
                                        `\nÉvolution volume : ${addArrow(
                                            printedSellsEvolution
                                        )} ${printedSellsEvolution} % ${printedClient}\nÉvolution utilisateurs finaux : ${addArrow(
                                            printedClientsEvolution
                                        )} ${printedClientsEvolution} %`
                                    )
                            }
                        }
                        const printedTooltip = getTooltip(env.name)

                        return printedTooltip
                    },
                },
            },
        },
    }

    const sortedType = sortClients(
        clients.data,
        clients.labels,
        clients.clients,
        clients.evolutionClients,
        clients.evolution,
        clients.caData,
        clients.evolutionCa,
        clients.shareVolume,
        clients.shareCa,
        clients.evolutionShareVolume,
        clients.evolutionShareCa,
        clients.evolutionVolume,
        clients.evolutionUnitVolume,
        clients.dataUnit
    )


    const sortedClients = {
        data: sortedType.data,
        labels: sortedType.labels,
        clients: sortedType.clients,
        evolutionClients: sortedType.evolutionClients,
        evolution: sortedType.evolutions,
        caData: sortedType.caData,
        evolutionCa: sortedType.evolutionCa,
        shareVolume: sortedType.shareVolume,
        shareCa: sortedType.shareCa,
        evolutionShareVolume: sortedType.evolutionShareVolume,
        evolutionShareCa: sortedType.evolutionShareCa,
        evolutionVolume: sortedType.evolutionVolume,
        evolutionUnitVolume: sortedType.evolutionUnitVolume,
        unitVolume: sortedType.unitVolume
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            sx={{ height: '100%' }}
        >
            {isEmptyDoughtnut(sortedType.data) ? (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    p={4}
                >
                    <PrimaryText color={BLACK}>
                        Aucun résultat sur la période sélectionnée,
                    </PrimaryText>
                    <PrimaryText bold color={BLACK}>
                        modifiez vos filtres.
                    </PrimaryText>
                    {/* TODO : we suppress data in back, we need to send clientsLastYear 
                    <MUITooltip
                        title={getEmptyDoughtnutTooltip(
                            sortedType.labels,
                            sortedType.clientsLastYear
                        )}
                    > */}
                    <PrimaryText mt="1vh" textDecoration='underline'>
                        Voir A-1
                    </PrimaryText>
                    {/* </MUITooltip> */}
                </Grid>
            ) : (
                <Grid container flexDirection="row" height="70%">
                    <Grid
                        container
                        flex={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Doughnut
                            ref={chartRef}
                            updateMode="none"
                            style={{ maxWidth: '100%' }}
                            options={options as any}
                            data={{
                                datasets: [
                                    {
                                        data: sortedType.data,
                                        backgroundColor:
                                            labels[0].backgroundColor,
                                        borderWidth: 0,
                                        borderColor: 'black',
                                    },
                                ],
                            }}
                        />
                    </Grid>
                    {withLegend ?
                        <Grid
                            container
                            justifyContent="center"
                            flex={1.8}
                            sx={{
                                overflowY: 'auto',
                                overflowX: 'auto',
                                maxHeight: '90%',
                                minWidth: '12vw',
                            }}
                        >
                            {isLabels ? (
                                <DoughnutLabels
                                    setOpen={setOpen}
                                    setFieldSelected={setFieldSelected}
                                    clients={clients}
                                    hasDrillDownOnSegmentation={hasDrillDownOnSegmentation}
                                    segmentationLink={segmentationLink}
                                />
                            ) : (
                                'loading'
                            )}
                        </Grid> : (
                            <Grid item flex={3} sx={{ height: '100%', width: '12vw' }}>
                                {regionsGBH && <DashboardDataGrid
                                    columns={GBHRegionsColumnsDashboard()}
                                    drilldown={false}
                                    data={regionsGBH}
                                    drilldownType={DrillDownType.WAREHOUSES}
                                    envName={EnvName.GBH}
                                />}
                            </Grid>)}
                </Grid>
            )
            }
        </Grid >
    )
}

export default DoughnutChart
