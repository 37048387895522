import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'
import { ProductsPageGBH } from '../../../../../../pages/private/Foodservice/GBH/products/ProductsPage'
import { SuppliersPageGBH } from '../../../../../../pages/private/Foodservice/GBH/suppliers/SuppliersPage'
import { WarehousesPageGBH } from '../../../../../../pages/private/Foodservice/GBH/warehouses/WarehousesPage'
import { ViewPage } from '../../../../views/ViewPage'
import { PlaybookPromo } from '../../../../../../pages/private/Foodservice/PlaybookPromo'


export const GBHRouter = (companyModules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'pointsdevente/',
        element: <WarehousesPageGBH />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'fournisseurs/',
        element: <SuppliersPageGBH />,
        title: PagesName.SUPPLIERS,
    },
    {
        path: 'produits/',
        element: <ProductsPageGBH />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
    ...(companyModules.includes(KaryonModules.PROMO) ?
        [] :
        [{
            path: 'promoDemo/',
            element: <PlaybookPromo />,
            title: PagesName.PROMODEMO,
        },]),
]
