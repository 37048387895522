import React, { useCallback, useEffect, useState } from 'react'
import Loading from '../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../components/features/screens/WaitingScreen'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { fetchDashboard } from '../../../../redux/global/globalSlice'
import { EnvStatus, UsersStatus } from '../../../../redux/user/userTypes'
import { StandaloneScreen } from '../../../../components/features/screens/StandAloneScreen'
import { fetchProductsTour } from '../../../../redux/product-tour/productsTourSlice'
import { ProductTourDialog } from '../../../../components/features/ProductTourDialog'
import { getDashboardConfig } from '../../../../components/features/views/utilsView'


export const DashboardLayout = () => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const dashboard = useAppSelector((state) => state.global.fsDashboardData)
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { unit, env } = useAppSelector((state) => state.global)
    const { productTour } = useAppSelector((state) => state.productsTour)
    const { status, brand } = useAppSelector((state) => state.user.user)

    const _fetchProductsTour = useCallback(async () => {
        await dispatch(fetchProductsTour())
    }, [dispatch])

    useEffect(() => {
        _fetchProductsTour()
    }, [])

    useEffect(() => {
        if (brand && activeFsFilters.startDate) {
            dispatch(fetchDashboard(activeFsFilters)).then(() => {
                setIsFirstLoad(false)
            })
        } else if (activeFsFilters.startDate === '') {
            setIsFirstLoad(false)
        }
    }, [brand, activeFsFilters, unit])

    if (status === UsersStatus.STANDALONE) {
        return <StandaloneScreen />
    }

    if (env.status !== EnvStatus.ON_LOADING_INIT && !dashboard.lineChartDashboard && !dashboard.segmentationDashboard && !dashboard.kpisDashboard && !dashboard.topFlopDashboard && !dashboard.lineChartDashboard && !dashboard.topTabDashboard && !dashboard.histogramDashboard
    ) {
        return <Loading />
    }

    if (
        !activeFsFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    return <Grid>
        {dashboard.lineChartDashboard && productTour && (
            <ProductTourDialog
                title={productTour.title}
                productTourId={productTour._id}
            />
        )}
        {getDashboardConfig(env.name, unit)}
    </Grid>

}
