import React from 'react'
import { Grid } from '@mui/material'
import { BLACK, DARK_GREY, GREY, LIGHT_GREY } from '../ui/Colors'
import NumberWithSpace from '../ui/NumberWithSpace'
import { PrimaryText, SecondaryText, SubTitle } from '../ui/Text'
import Tendency from '../ui/Tendency'
import { Units } from '../../redux/global/globalTypes'

export interface KpiCard {
    name: string
    number: number
    units?: string
    difference?: number
    route?: string
    icon?: any
    evolution?: number
}

export interface DoubleKpiCard {
    name: string
    number: number
    units?: string
    difference?: number
    route?: string
    icon?: any
    evolution?: number
    units2?: string
    number2?: number
    difference2?: number
    evolution2?: number
    evolutionUnit?: Units
    evolution2Unit?: Units
    unitType?: Units
    unitType2?: Units
}

const KpiNumber = ({ difference, number, units, evolution, isDouble = false, unitType, evolutionUnit, evolution2Unit }: { difference: number, number: number, units: string, evolution: number, isDouble?: boolean, unitType?: string, evolutionUnit?: Units, evolution2Unit?: Units }) => {
    return (
        <Grid
            container
            alignItems="flex-bottom"
            justifyContent="space-between"
            wrap="nowrap"
        >
            <Grid item>
                <Grid container alignItems="baseline">
                    {isDouble ? (
                        <Grid container>
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace number={number} />
                                {units}
                            </SecondaryText>
                            {unitType && <SecondaryText ml={0.5} color={DARK_GREY}>
                                en {unitType}
                            </SecondaryText>}
                        </Grid>
                    ) :
                        <SecondaryText color={BLACK}>
                            <NumberWithSpace number={number} />
                            {units}
                        </SecondaryText>}
                </Grid>
            </Grid>
            {difference != undefined && (
                <Grid item>
                    <Grid
                        container
                        alignItems="flex-end"
                    >
                        <Tendency
                            isDoubleKpi={isDouble}
                            kpi
                            difference={difference}
                            unit={units}
                            evolution={evolution}
                            evolutionUnit={evolutionUnit}
                            evolution2Unit={evolution2Unit}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export const KpiCard = ({
    name,
    number,
    units,
    difference,
    icon,
    evolution
}: KpiCard) => {

    return (
        <Grid width="100%">
            <Grid container justifyContent="space-between" mb="2vh">
                <PrimaryText bold color={BLACK} gutterBottom mb="1vh">
                    {name}
                </PrimaryText>
                {icon ? icon : <></>}
            </Grid>
            <KpiNumber difference={difference} number={number} units={units} evolution={evolution} />
        </Grid>
    )
}


export const DoubleKpiCard = ({
    name,
    number,
    units,
    difference,
    icon,
    evolution,
    units2,
    number2,
    difference2,
    evolution2,
    evolutionUnit,
    evolution2Unit,
    unitType,
    unitType2
}: DoubleKpiCard) => {
    return (
        <Grid fontSize={"2vh"} width="100%">
            <Grid container justifyContent="space-between" mb="2vh">
                <PrimaryText bold color={BLACK} gutterBottom mb="1vh">
                    {name}
                </PrimaryText>
                {icon ? icon : <></>}
            </Grid>
            <KpiNumber isDouble difference={difference} number={number} units={units} evolution={evolution} unitType={unitType} evolutionUnit={evolutionUnit} />
            <KpiNumber isDouble difference={difference2} number={number2} units={units2} evolution={evolution2} unitType={unitType2} evolution2Unit={evolution2Unit} />
        </Grid>
    )
}
