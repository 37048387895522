import React, { useState } from 'react'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { useCallback, useEffect } from 'react'
import {
    getFsProductsNotDistributedColumns,
    getFSRows,
    getProductsColumns,
} from './setupDrillDown'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveFsFilters } from '../../../redux/filters/filtersTypes'
import {
    fetchDrillDownProducts,
    fetchDrillDownProductsNotDistributed,
} from '../../../redux/fsProducts/fsProductsSlice'
import Loading from '../screens/LoadingScreen'
import { DrillDownType } from './ProductsDrillDownContent'
import { ProductNotDistributedItemGBH } from '../../../redux/fsProducts/fsProductsTypes'

export const WarehousesGBHDrillDownContent = ({
    row,
    isFirstVisit,
    value,
    handleChange,
    type,
}: DrillDownContentProps) => {
    const [isLoadingProducts, setLoadingProducts] = useState(true)
    const [isLoadingProductsNotDistributed, setLoadingProductsNotDistributed] = useState(true)

    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownProducts, drillDownProductsNotDistributed } = useAppSelector(
        (state) => state.fsProducts
    )
    const { unit } = useAppSelector((state) => state.global)

    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )
    const _fetchDrillDownProductsNotDistributed = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownProductsNotDistributed(filters))
        },
        [dispatch]
    )

    const productsLength = drillDownProducts ? drillDownProducts.length : ''
    const productsNotDistributedLength = drillDownProductsNotDistributed ? drillDownProductsNotDistributed.length : ''

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
        products: activeFsFilters.products,
        regions: [row.row.id],
        categories: activeFsFilters.categories,
        suppliers: activeFsFilters.suppliers,
        universes: activeFsFilters.universes
    }

    useEffect(() => {
        const fetchDataProducts = async () => {
            await _fetchDrillDownProducts(filters)
        }

        const fetchDataProductsNotDistributed = async () => {
            await _fetchDrillDownProductsNotDistributed(filters)
        }

        fetchDataProductsNotDistributed().then(() => setLoadingProductsNotDistributed(false))

        fetchDataProducts().then(() => setLoadingProducts(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Références (${productsLength})`,
                `Références non distribuées (${productsNotDistributedLength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoadingProducts && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProducts)}
                        columns={getProductsColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.PRODUCTS,
                            value: row.row.products,
                            oldValue: row.row.productsLastYear,
                        })}
                    />
                )}
                {isLoadingProducts && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownProductsNotDistributed && !isLoadingProductsNotDistributed && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownProductsNotDistributed as ProductNotDistributedItemGBH[])}
                        columns={getFsProductsNotDistributedColumns(type, unit)}
                        message={`Psssst, ci-dessous la précieuse liste des références que vous pourriez pousser dans le dépôt ${row.row.warehouse}`}
                    />
                )}
                {isLoadingProductsNotDistributed && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
