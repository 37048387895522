import { WarehouseItemWithLiterAndUVC } from "../fsProducts/fsProductsTypes"

export enum EnvType {
    RETAIl = 'retail',
    FOOD_SERVICE = 'food-service',
    SETTING = 'setting',
    FOOD_SERVICE_CROSS = 'food-service-cross',
    RETAIL_CROSS = 'retail-cross',
    SUPER_ADMIN = 'super-admin'
}

export enum EnvStatus {
    OUTDATED = 'outdated',
    ACTIVATED = 'activated',
    INACTIVATED = 'inactivated',
    ON_LOADING = 'onload',
    ON_LOADING_INIT = 'onload-init',
}

export enum EnvName {
    RETAIL_CROSS = 'Retail',
    CARREFOUR = 'Carrefour',
    AUCHAN = 'Auchan',
    LECLERC = 'Leclerc',
    INTERMARCHE = 'Intermarché',
    GBH = 'GBH',

    FOOD_SERVICE_CROSS = 'Foodservice',
    FRANCE_FRAIS = 'France Frais',
    TRANSGOURMET = 'Transgourmet',
    TRANSGOURMETPLUS = 'Transgourmet plus',
    MYDISTRIB = 'Mes Distributeurs',
    METRO = 'Metro',
    EPISAVEURS = 'EpiSaveurs',
    PASSIONFROID = 'PassionFroid',
    SODEXO = 'Sodexo',
    CERCLEVERT = "Cercle vert",
    DISTRIBOISSONS = "Distriboissons",
    C10 = "C10",

    SETTING = 'Paramètres',
    SUPER_ADMIN = "Super admin",
    SELLIN = "Sell-in"
}

export enum ConnectorType {
    MANUAL = 'manual',
    AUTOMATED = 'automated',
}
export interface Env {
    name: EnvName
    type: EnvType
    status: EnvStatus
    connectorType?: ConnectorType
}

interface DistributerData {
    distributed: boolean
    distributer: string[]
    data: string[]
    hasOthers: boolean
}

export interface User {
    _id: string
    supabaseId?: string
    email: string
    firstname: string
    lastname: string
    job: string
    phoneNumber?: string
    brand: string
    retail: DistributerData
    fs: DistributerData
    env: Env[]
    companyId?: string
    role: UsersRole
    status: UsersStatus
    useCases: string[]
    modules: KaryonModules[]
    companyModules: KaryonModules[]
}

export enum KaryonModules {
    PROMO = 'promotions',
    CROSS = 'cross', //TODO: faire un cross retail et un cross FS
    SELL_IN_RETAIL = 'Sell-in Retail',
}

export enum UsersRole {
    ADMIN = 'admin',
    USER = 'user',
    SUPER_ADMIN = "super-admin"
}

export enum UsersStatus {
    PENDING = 'pending',
    ACTIVATED = 'activated',
    DEACTIVATED = 'deactivated',
    STANDALONE = 'non rattaché',
}
export interface userState {
    user: User | null
    error?: string
}

// DASHBOARD FS
export interface Products {
    _id: string
    clients: number
    differenceWeight: number
    oldVolume: number
    products: number
    vmm: number
    volume: number
    name: string
    warehouses?: number
}

export interface FsFFRKPIs {
    warehouses: number
    differenceWarehouses: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    differenceVmm: number
    evolutionVmm: number
    evolutionClients: number
    evolutionWarehouses: number
    evolutionVolume: number
}

export interface FsTGMPlusKPIs {
    warehouses: number
    warehousesLastYear: number
    clients: number
    clientsLastYear: number
    transactions: number
    transactionsLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    vmmLastYear: number
    evolutionVmm: number
    evolutionClients: number
    evolutionWarehouses: number
    evolutionVolume: number
    evolutionTransactions: number
}

export interface FsGBHKPIs {
    warehouses: number
    warehousesLastYear: number
    ca: number
    caLastYear: number
    products: number
    productsLastYear: number
    volume: number
    volumeLastYear: number
    evolutionVolume: number
    evolutionProducts: number
    evolutionCA: number
    evolutionWarehouses: number
    differenceVolume: number
    differenceProducts: number
    differenceCa: number
    differenceWarehouses: number
    shareCa: number
    caShareLastYear: number
    shareVolume: number
    volumeShareLastYear: number
    differenceShareCa: number;
    differenceShareVolume: number;
    evolutionShareVolume: number;
    evolutionShareCa: number;
}

export interface FsC10AndDistriboissonsKPIs {
    warehouses: number
    evolutionWarehouses: number
    differenceWarehouses: number
    products: number
    evolutionProducts: number
    differenceProducts: number
    volume: number
    evolutionVolume: number
    differenceVolume: number
    vmmVolume: number
    evolutionVmmVolume: number
    differenceVmmVolume: number
    vmmUnitVolume: number
    evolutionVmmUnitVolume: number
    differenceVmmUnitVolume: number
    unitVolume: number
    differenceUnitVolume: number
    evolutionUnitVolume: number
}

export interface FsEpiSaveursKPIs {
    warehouses: number
    differenceWarehouses: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    vmm: number
    differenceVmm: number
    products: number
    productsLastYear: number
    evolutionWarehouses: number
    evolutionClients: number
    evolutionVolume: number
    evolutionVmm: number
    evolutionProducts: number
}

export interface FsCercleVertKPIs {
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
    evolutionProducts: number
    evolutionClients: number
    evolutionVolume: number
}

export interface FsMetroKPIs {
    warehouses: number
    differenceWarehouses: number
    volume: number
    volumeLastYear: number
    vmm: number
    differenceVmm: number
    products: number
    productsLastYear: number
    evolutionVmm: number
    evolutionProducts: number
    evolutionWarehouses: number
    evolutionVolume: number
}

export interface FsCrossKPIs {
    warehouses: number
    warehousesLastYear: number
    volume: number
    volumeLastYear: number
    VMM: number
    VMMLastYear: number
}

export interface FsSodexoKPIs {
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
    evolutionProducts: number
    evolutionVolume: number
}

export interface FsTGMKPIs {
    warehouses: number
    warehousesLastYear: number
    clients: number
    clientsLastYear: number
    volume: number
    volumeLastYear: number
    products: number
    productsLastYear: number
    evolutionClients: number
    evolutionProducts: number
    evolutionWarehouses: number
    evolutionVolume: number
}

export interface Clients {
    labels: string[]
    data: number[]
    evolution: number[]
    evolutionClients: number[]
    dataLastYear: number[]
    clients: number[]
    clientsLastYear: number[]
    products: number[]
    differenceWeight: number[]
    differenceClients: number[]
    differenceProducts: number[]
    productsLastYear: number[]
    warehouses: number[]
    warehousesLastYear: number[]
    VMM: number[]
    VMMLastYear: number[]
    differenceVMM: number[]
}
export interface RegionTableSegmentationC10AndDistriboissons {
    volume: number;
    warehouses: number;
    differenceWarehouses: number;
    id: string;
    region: string;
}
export interface HistogramDataGBHItem {
    labels: string[];
    data: number[];
    shareVolumeData: number[];
    shareCaData: number[];
    caData: number[];
}
export interface Histogram {
    current: {
        labels: string[]
        data: number[]
        shareVolumeData?: number[];
        shareCaData?: number[];
        caData?: number[];
    }
    old: {
        labels: string[]
        data: number[]
        shareVolumeData?: number[];
        shareCaData?: number[];
        caData?: number[];
    }
}

export interface TopTabDashboard {
    _id: string
    lastYearSells: number
    sells: number
    evolYear: string
    currentDate: string
    lastYearDate: string
}

interface Distributors {
    id: string
    volume: number
    warehouses: number
    VMM: string
    percentage: number
}

export interface Categories {
    id: string
    volume: number
    volumeLastYear: number
    differenceWeight: number
}

export interface Regions {
    id: string
    volume: number
    pdm: number
    differencePDM: number
    differenceWeight: number
}


export interface FsDashboard {
    topFlopDashboard: Products[]
    lineChartDashboard: LineChartDashboard
    kpisDashboard: FsFFRKPIs | FsTGMKPIs | FsGBHKPIs | FsC10AndDistriboissonsKPIs | FsEpiSaveursKPIs | FsCercleVertKPIs | FsMetroKPIs | FsSodexoKPIs | FsTGMPlusKPIs
    segmentationDashboard: SegmentationDashboard
    histogramDashboard: Histogram
    topTabDashboard: TopTabDashboard[] | Categories[] | WarehouseItemWithLiterAndUVC[]
    regions: Regions[]
}

export enum BLOCTYPE {
    SEGMENTATION_UF = "segmentation_UF",
    SEGMENTATION_WAREHOUSES = "segmentation_warehouses",
    TABLE = "table",
}

export interface SegmentationDashboard {
    segmentationDashboard: Clients | SegmentationGBH | RegionTableSegmentationC10AndDistriboissons[],
    title: string
    type: BLOCTYPE
}
export interface LineChartDashboard {
    formatedProductsChart: ProductsChart[], legend: string[]
}
export interface FsCrossDashboard {
    productsChart: ProductsChart[]
    legendChart: string[]
    clients: Clients
    productsTopFlop: any
    KPIs: FsCrossKPIs
    distributorsList: Distributors[]
}

export interface SegmentationGBH {
    segmentation: Clients,
    regions: Regions[],
}

export interface WarehousesSegmentationC10AndDistriboissons {
    labels: string[];
    data: number[];
    evolutionVolume: number[];
    dataUnit: number[];
    evolutionUnitVolume: number[];
}

export interface ProductsChart {
    name: string
    labels: string[]
    data: number[]
    evolution: number[]
    unitVolume?: number[]
    evolutionUnitVolume?: number[]
}

// DASHBOARD RETAIL
interface RetailKpis {
    stores: number
    storesLastYear: number
    sells: number
    sellsLastYear: number
    products: number
    productsLastYear: number
    vmm: number
    vmmLastYear: number
    differenceSells: number
    evolutionSells: number
    differenceProducts: number
    evolutionProducts: number
    evolutionStores: number
    differenceStores: number
    differenceVMM: number
    evolutionVMM: number

}

export interface RetailProduct {
    _id: string
    stores: number
    evolution: number
    oldValue: number
    value: number
    vmm: number
    name: string
    differenceWeight: number
}

interface RetailProductsSplit {
    labels: string[]
    values: number[]
    evolution: number[]
    storesEvolution: number[]
    stores: number[]
}

interface Store {
    region: string
    stores: number
    values: number
}

interface GeoStore {
    store: string
    position: { lat: number; lng: number }
}


export interface ProductChartRetail { name: string; labels: string[]; data: number[] }
export interface RetailDashboard {
    kpis: RetailKpis
    products: RetailProduct[]
    productsChart: ProductChartRetail[]
    productsSplit: RetailProductsSplit
    stores: Store[]
    geoStores: GeoStore[]
}

// Invitations

export interface UserInviteResponse {
    result: { success: string } | { error: string }
}

export interface UserInvitation {
    role: UsersRole
    email: string
}

export enum CodeErrorUserInvitation {
    USER_EXIST = 'user_exist',
    USER_INVITED = 'user_invited',
    USER_DEACTIVATED = 'user_deactivated',
}

export interface UserInvitationValidityResponse {
    validity: boolean
    message: string
    email: string
}
