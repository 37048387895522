import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Checkbox, Grid } from '@mui/material'
import { isSelected, toggleChangeFilters } from './FiltersFunction'
import { FiltersData } from './MainFilter'
import { SecondaryText } from '../../../ui/Text'
import { BLACK, MATERIAL_BLUE, WHITE } from '../../../ui/Colors'
import { Separator } from '../../../ui/Separator'
import { MenuType } from '../utils'

export const checkboxCSS = {
    minWidth: { xs: '40px', md: '2vw' },
    padding: { xs: '20px', md: '1vw' },
    '& svg': {
        width: { xs: '22px', md: '1.5vw' },
        height: { xs: '22px', md: '2.5vh' },
    },
    mr: '0.3vw',
    ml: '0.5vw',
}

const flattenData = (data) => {
    let flatList = [];

    const transform = (items) => {
        items.forEach(item => {
            flatList.push(item);
            if (item.children) {
                transform(item.children);
            }
        });
    };

    transform(data);
    return flatList;
};

export const FilterComponent = ({
    data,
    filters,
    setFieldValue,
    type,
    search,
    depth,
}: {
    data: FiltersData[]
    filters: string[]
    setFieldValue: (field: string, value: string[]) => void
    type: MenuType
    search: string
    depth: number
}) => {
    const [flattenedData, setFlattenedData] = useState(flattenData(data));
    const [visibleData, setVisibleData] = useState(flattenedData.slice(0, 20)); //display data by 20 elements
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const newFlattenedData = flattenData(data);
        setFlattenedData(newFlattenedData);
        setVisibleData(newFlattenedData.slice(0, 20));
    }, [data]);

    const loadMoreItems = useCallback((e: any) => {
        const targetElement = e.target
        const isBottom = targetElement.scrollTop + targetElement.offsetHeight >= targetElement.scrollHeight

        if (isBottom && !isLoading && (visibleData.length < flattenedData.length)) {
            setIsLoading(() => true);
            const nextData = flattenedData.slice(
                visibleData.length,
                visibleData.length + 20
            );
            setVisibleData((prevData) => {
                return [...prevData, ...nextData]
            })

            setIsLoading(() => {
                return false
            });
        }
    }, [visibleData]);

    return (
        <Grid
            sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                scrollBehavior: 'smooth',
                maxHeight: '55vh',
                background: WHITE,
            }}
            onScroll={loadMoreItems}

        >
            {
                visibleData.map((el, index) => {
                    let nameWithSearchOccurences = el.name
                        .toLowerCase()
                        .split(search.toLowerCase())
                        .join('$')

                    return (
                        <Grid key={index}>
                            <Grid
                                width="36vw"
                                container
                                alignItems="center"
                                sx={{
                                    cursor: 'pointer',
                                    paddingLeft: el.level === 1 ? 0 : `${el.level}vw`,
                                }}
                                onClick={() => {
                                    const values = toggleChangeFilters(
                                        el.level,
                                        el,
                                        filters
                                    )
                                    setFieldValue(type, values)
                                }}
                            >
                                <Checkbox
                                    checked={isSelected(
                                        el,
                                        filters,
                                        el.level,
                                        depth
                                    )}
                                    sx={checkboxCSS}
                                />
                                <SecondaryText
                                    color={BLACK}
                                    bold={el.level < depth}
                                >
                                    {nameWithSearchOccurences
                                        .split('')
                                        .map((el, index) => {
                                            return el === '$' ? (
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        color: MATERIAL_BLUE,
                                                    }}
                                                    key={index}
                                                >
                                                    {search.toUpperCase()}
                                                </span>
                                            ) : (
                                                el.toUpperCase()
                                            )
                                        })}
                                </SecondaryText>
                                {el.level === 1 && el.children && (
                                    <Separator mt="0.5vh" mb="0.5vh" />
                                )}
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid >
    )
}
