import { Grid } from "@mui/material"
import React, { useSyncExternalStore } from "react"
import FsKpis, { ConfigKPIs } from "../../Pages/Dashboard/FsKpis"
import ChartBlock from "../../../../../components/features/ChartBlock"
import TopFlop from "../../Pages/Dashboard/TopFlop"
import LineChart from "../../../../../components/features/foodService/dashboard/LineChart"
import FsCrossSegmentation from "./FsCrossSegmentation"
import { LoadingWithoutText } from "../../../../../components/features/screens/LoadingScreen"
import { FsCrossTable } from "./FsCrossTable"
import { useAppSelector } from "../../../../../redux/hooks"
import { Units } from "../../../../../redux/global/globalTypes"
import { BLOCTYPE, RegionTableSegmentationC10AndDistriboissons } from "../../../../../redux/user/userTypes"
import { DataGrid } from "@mui/x-data-grid"
import { getResizeSnapshot, subscribeToResize } from "../../../../../helpers/ExternalStore"
import { C10AndDistriboissonsColumnsSegmentationTable } from "../../utils"

export const KPIsFsCrossConfig = ({
    KPIs,
    unit
}: {
    KPIs: any,
    unit: Units
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Quantité totale facturée',
            units: unit,
            description: `Quantité totale facturée sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.differenceVolume,
            evolution: KPIs.evolutionVolume,
        },
        {
            id: 2,
            name: `Nombre de références`,
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: KPIs.products,
            difference: KPIs.differenceProducts,
            evolution: KPIs.evolutionProducts,
        },
        {
            id: 3,
            name: 'VMM',
            units: unit,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.VMM),
            difference: KPIs.differenceVMM,
            evolution: KPIs.evolutionVMM
        },
    ]

export const FsCrossDashboardContent = () => {
    const { segmentationDashboard, kpisDashboard, topFlopDashboard, lineChartDashboard, topTabDashboard } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)

    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )

    return (<Grid
        container
        direction="column"
        wrap="nowrap"
        height="90vh"
        p="1vw"
    >
        <FsKpis KPIsConfig={KPIsFsCrossConfig} kpisDashboard={kpisDashboard} />
        <Grid
            container
            direction="column"
            wrap="nowrap"
            justifyContent="flex-start"
            pt="1vw"
            sx={{ height: '80vh' }}
        >

            <Grid container sx={{ height: '40%' }} wrap="nowrap">
                <ChartBlock
                    title={`Top/Flop par famille par mois (${unit})`}
                >
                    {topFlopDashboard ?
                        <TopFlop topFlopDashboard={topFlopDashboard} /> : <LoadingWithoutText />}
                </ChartBlock>
                <Grid item flex={3} pl="1vw">
                    <ChartBlock
                        title={`Vente par famille par mois (${unit})`}
                    >
                        {topFlopDashboard && lineChartDashboard ? <LineChart topFlopDashboard={topFlopDashboard} lineChartDashboard={lineChartDashboard} /> : <LoadingWithoutText />}
                    </ChartBlock>
                </Grid>
            </Grid>
            <Grid container wrap="nowrap" pt="1vw" sx={{ height: '62%' }}>
                <Grid
                    item
                    flex={1.5}
                    sx={{
                        height: '100%', width: '14vw', '& .MuiDataGrid-columnHeaderTitleContainer': {
                            justifyContent: 'center !important',
                        },
                        ...segmentationDashboard && segmentationDashboard.type === BLOCTYPE.TABLE && {
                            '& div.MuiDataGrid-columnHeader:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(1)':
                            {
                                justifyContent: 'flex-start !important',
                            },
                            '& .MuiDataGrid-cell--textLeft:first-of-type': {
                                justifyContent: 'flex-start !important',
                            },
                            '& .MuiGrid-container': {
                                justifyContent: 'center',
                            },
                        }
                    }}
                >
                    <ChartBlock title={segmentationDashboard ? `${segmentationDashboard.title} (${unit})` : ""}>
                        {segmentationDashboard && (segmentationDashboard.type === BLOCTYPE.SEGMENTATION_WAREHOUSES || segmentationDashboard.type === BLOCTYPE.SEGMENTATION_UF) ?
                            (<FsCrossSegmentation clients={topTabDashboard} />) : segmentationDashboard && segmentationDashboard.type === BLOCTYPE.TABLE ?
                                <DataGrid
                                    hideFooterSelectedRowCount={true}
                                    rows={segmentationDashboard.segmentationDashboard as RegionTableSegmentationC10AndDistriboissons[]}
                                    columns={C10AndDistriboissonsColumnsSegmentationTable(unit)}
                                    getRowId={(row) => row.id}
                                    rowHeight={height * 0.035}
                                    hideFooter
                                />
                                : <LoadingWithoutText />}
                    </ChartBlock>
                </Grid>
                <Grid
                    container
                    flexWrap="nowrap"
                    direction="column"
                    flex={2}
                    pl="1vw"
                    sx={{ height: '100%' }}
                >
                    <ChartBlock title="Répartition des ventes par distributeur">
                        {topTabDashboard ?
                            <FsCrossTable distributorsList={topTabDashboard} />
                            : <LoadingWithoutText />}
                    </ChartBlock>
                </Grid>
            </Grid>
        </Grid>
    </Grid>)
}