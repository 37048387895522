import React from 'react'
import DoughnutChart from '../../../../../components/features/foodService/dashboard/graph/DoughnutChart'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid } from '@mui/material'
import { MetroNoClientsData } from '../../Pages/Dashboard/FsSegmentation'

const FsCrossSegmentation = ({
    setFieldSelected,
    setOpen,
    clients
}: {
    setFieldSelected?: (field: any) => void
    setOpen?: (open: boolean) => void
    clients: any
}) => {
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)

    return (
        <Grid height="100%" container justifyContent="center">
            {JSON.stringify(activeFsCrossFilters.distributors) === JSON.stringify(["Metro"]) && (
                <MetroNoClientsData />
            )}
            {clients && JSON.stringify(activeFsCrossFilters.distributors) !== JSON.stringify(["Metro"]) && <DoughnutChart
                setOpen={setOpen}
                setFieldSelected={setFieldSelected}
            />}
        </Grid>

    )
}

export default FsCrossSegmentation
