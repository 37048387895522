import React, { useState } from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { PagesName } from '../../../../../components/features/navigation/types/pages'
import { useAppSelector } from '../../../../../redux/hooks'
import { Grid } from '@mui/material'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { DetailTab } from '../../../../../components/ui/TabsForDataTableComponent'
import { SuppliersLayout } from '../../layout/SuppliersLayout'
import { SuppliersPerBrand } from './SuppliersPerBrand'
import { SuppliersPerSegment } from './SuppliersPerSegment'
import { SuppliersPerRegion } from './SuppliersPerRegion'
import { Histogram } from '../../../../../components/features/foodService/dashboard/histogram/Histogram'
import { Units } from '../../../../../redux/global/globalTypes'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import DrillDown from '../../../../../components/features/drillDowns/DrillDown'
import { DataTableComponent } from '../../../DataTableComponent'
import { GBHSuppliersColumns } from '../../utils'
import { GridCellParams } from '@mui/x-data-grid'
import { roundIfNotZero } from '../../../../../helpers/roundIfNotZero'
import { LoadingWithoutText } from '../../../../../components/features/screens/LoadingScreen'

export const suppliersLabels = [
    {
        id: 0,
        title: 'PDM A-1',
        backgroundColor: '#ADB2D3',
    },
    {
        id: 1,
        title: 'PDM A',
        backgroundColor: '#3A4D87',
    }
]


const dataGridConfig = () => {
    return [
        {
            id: "1",
            label: "Par territoire",
            children: <SuppliersPerRegion />
        },
        {

            id: "2",
            label: "Par segment",
            children: <SuppliersPerSegment />
        },
        {
            id: "3",
            label: "Par marque",
            children: <SuppliersPerBrand />
        },
    ]
}

export const SuppliersPageGBH = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { suppliers } = useAppSelector((state) => state.fsProducts)
    const [isDrilldownSuppliersOpen, setDrilldownSuppliersOpen] = useState(false)
    const [fieldSuppliersSelected, setFieldProductsSelected] =
        useState<GridCellParams<any>>()
    const [suppliersValue, setSuppliersValue] = useState(0)
    const config = dataGridConfig()

    const handleCloseSuppliers = () => {
        setDrilldownSuppliersOpen(false)
    }

    const data = suppliers && suppliers.suppliersHistogram.current ? {
        labels: suppliers.suppliersHistogram.current.labels,
        datasets:
            [
                {
                    data: suppliers.suppliersHistogram.old.data,
                    backgroundColor: suppliersLabels[0].backgroundColor,
                    borderColor: suppliersLabels[0].backgroundColor,
                },
                {
                    data: suppliers.suppliersHistogram.current.data,
                    backgroundColor: suppliersLabels[1].backgroundColor,
                    borderColor: suppliersLabels[1].backgroundColor,
                },
            ],
    } : null

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const value = model.raw.toFixed(2)

                        return model.datasetIndex === 1
                            ? `En ${Units.uvc}`
                            : `PDM A-1 en CA : ${value} ${Units.percent}`
                    },
                    afterLabel: (model: any) => {
                        const valueLastYear =
                            suppliers.suppliersHistogram.old.data[model.dataIndex]
                        const value =
                            suppliers.suppliersHistogram.current.data[model.dataIndex]
                        const shareVolumeLastYear =
                            suppliers.suppliersHistogram.old.shareVolumeData[model.dataIndex]
                        const shareVolume =
                            suppliers.suppliersHistogram.current.shareVolumeData[model.dataIndex]

                        const shareVolumeDifference = shareVolume - shareVolumeLastYear
                        const shareCADifference = value - valueLastYear

                        const shareVolumeEvolution = shareVolumeDifference === 0
                            ? ''
                            : shareVolumeDifference > 0
                                ? `Évolution : ↑ ${roundIfNotZero(
                                    shareVolumeDifference
                                )}`
                                : `Évolution : ↓ ${roundIfNotZero(
                                    shareVolumeDifference
                                )}`

                        const difference = value - valueLastYear

                        const valueEvolution = difference === 0
                            ? ''
                            : difference > 0
                                ? `Évolution : ↑ ${roundIfNotZero(
                                    shareCADifference)}`
                                : `Évolution : ↓ ${roundIfNotZero(
                                    shareCADifference)}`

                        return model.datasetIndex === 1
                            ? `PDM A : ${shareVolume.toFixed(2)} ${Units.percent}\nPDM A-1 : ${shareVolumeLastYear.toFixed(2)} ${Units.percent}\n${shareVolumeEvolution}\n-\n En ${Units.ca}\n PDM A : ${value.toFixed(2)} ${Units.percent} \n PDM A-1 : ${valueLastYear.toFixed(2)} ${Units.percent}\n${valueEvolution}`
                            : `PDM A-1 en UVC : ${shareVolumeLastYear.toFixed(2)} ${Units.percent}`
                    },
                },
            },
        },
    }

    return (
        <PrivatePage title="Fournisseurs" period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.CLIENTS}
            />
            <SuppliersLayout>
                <Grid>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            height: '60vh',
                            '& .MuiDataGrid-row:hover #tendency': {
                                visibility: 'visible',
                            },
                        }}
                        p="1vw"
                        flexWrap="nowrap"
                    >
                        <ChartBlock
                            title={`Parts de marché`}
                        >
                            {suppliers && suppliers.suppliersHistogram ?
                                <Grid width="100%">
                                    <Histogram labels={suppliersLabels} data={data} options={options} height="45vh" />
                                </Grid> : <LoadingWithoutText />}
                        </ChartBlock>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            '& .MuiDataGrid-row:hover #tendency': {
                                visibility: 'visible',
                            },
                        }}
                        p="1vw"
                        flexWrap="nowrap"
                    >
                        <ChartBlock
                            title={`Performances par territoire - segment - marque`}
                        >{suppliers &&
                            <DetailTab data={config}></DetailTab>
                            }
                        </ChartBlock>
                    </Grid>
                    <Grid p="1vw">
                        <ChartBlock
                            title={`Performances par fournisseur`}
                        >
                            {suppliers && <Grid height="45vh">
                                <DrillDown
                                    type={DrillDownType.SUPPLIERSGBH}
                                    open={isDrilldownSuppliersOpen}
                                    title={fieldSuppliersSelected && `${fieldSuppliersSelected.row.supplier}`}
                                    value={suppliersValue}
                                    setValue={setSuppliersValue}
                                    row={fieldSuppliersSelected}
                                    handleClose={handleCloseSuppliers}
                                />
                                <DataTableComponent
                                    type={DrillDownType.SUPPLIERSGBH}
                                    setOpen={setDrilldownSuppliersOpen}
                                    setFieldSelected={setFieldProductsSelected}
                                    setValue={setSuppliersValue}
                                    rows={suppliers.suppliersList ? suppliers.suppliersList : []}
                                    columns={GBHSuppliersColumns("brand")}
                                    getRowId={(row) => row.id}
                                />
                            </Grid>}
                        </ChartBlock>
                    </Grid>
                </Grid>
            </SuppliersLayout>
        </PrivatePage >
    )
}