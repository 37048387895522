import React, { useSyncExternalStore } from 'react'
import { Grid } from '@mui/material'
import FsKpis, { ConfigKPIs } from './Dashboard/FsKpis'
import FsSegmentation from './Dashboard/FsSegmentation'
import { HistogramFS } from './Dashboard/HistogramFS'
import { useAppSelector } from '../../../../redux/hooks'
import {
    BLOCTYPE,
    EnvName,
    FsGBHKPIs,
    RegionTableSegmentationC10AndDistriboissons,
    SegmentationGBH,
} from '../../../../redux/user/userTypes'
import DashboardDataGrid from './Dashboard/DashboardDataGrid'
import { Units } from '../../../../redux/global/globalTypes'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import ChartBlock from '../../../../components/features/ChartBlock'
import { LoadingWithoutText } from '../../../../components/features/screens/LoadingScreen'
import TopFlop from './Dashboard/TopFlop'
import { getResizeSnapshot, subscribeToResize } from '../../../../helpers/ExternalStore'

export const DashboardGBHPage = ({
    KPIsConfig,
    dashboardGridDataTitle,
    warehousesConfig,
    segmentationTableColumns,
    segmentationLink
}: {
    KPIsConfig: ({
        KPIs,
        envName,
        unit
    }: {
        KPIs: FsGBHKPIs
        envName: EnvName
        unit: Units
    }) => ConfigKPIs[]
    warehousesConfig: GridColDef[]
    dashboardGridDataTitle: string
    segmentationTableColumns?: GridColDef[]
    segmentationLink: string
}) => {
    const { name } = useAppSelector((state) => state.global.env)
    const companyName = useAppSelector((state) => state.user.user.brand)
    const dashboardGridData = useAppSelector((state) => state.global.fsDashboardData.topTabDashboard)
    const dashboardGridDataLink = `/food-service/${name}/fournisseurs`
    const { segmentationDashboard, kpisDashboard, topFlopDashboard, topTabDashboard, histogramDashboard } = useAppSelector((state) => state.global.fsDashboardData)
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <FsKpis KPIsConfig={KPIsConfig} kpisDashboard={kpisDashboard} />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '80vh' }}
            >
                <Grid container wrap="nowrap"
                    sx={{ height: '40%' }}>
                    <ChartBlock
                        title={`TOP / FLOP - Références ${companyName} (${Units.uvc})`}
                        link={`/food-service/${name}/produits`}
                    >
                        {topFlopDashboard ?
                            <TopFlop topFlopDashboard={topFlopDashboard} /> : <LoadingWithoutText />}
                    </ChartBlock>
                    <Grid
                        pl="1vw"
                        flex={2}>
                        <ChartBlock
                            title={`Total des ventes ${companyName} par semestre (${Units.uvc})`}
                            link={`/food-service/${name}/produits`}
                        >
                            {histogramDashboard ? <HistogramFS /> : <LoadingWithoutText />}
                        </ChartBlock>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '60%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{ height: '100%', width: '14vw' }}
                    >
                        <Grid item flex={1.5} height="100%">
                            <ChartBlock
                                title={dashboardGridDataTitle}
                                link={dashboardGridDataLink}
                            >
                                {topTabDashboard ? <DashboardDataGrid
                                    columns={warehousesConfig}
                                    drilldown={false}
                                    data={dashboardGridData}
                                    envName={name}
                                />
                                    : <LoadingWithoutText />}
                            </ChartBlock >

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        flexWrap="nowrap"
                        direction="column"
                        flex={2}
                        pl="1vw"
                        sx={{ height: '100%' }}
                    >
                        <ChartBlock link={segmentationLink} title={segmentationDashboard ? `${segmentationDashboard.title}` : ""}>
                            {segmentationDashboard && (segmentationDashboard.type === BLOCTYPE.SEGMENTATION_WAREHOUSES || segmentationDashboard.type === BLOCTYPE.SEGMENTATION_UF) ?
                                (<FsSegmentation
                                    withLegend={false}
                                    segmentationDashboard={segmentationDashboard.segmentationDashboard as SegmentationGBH}
                                    segmentationLink={segmentationLink}
                                />) : segmentationDashboard && segmentationDashboard.type === BLOCTYPE.TABLE ?
                                    <DataGrid
                                        hideFooterSelectedRowCount={true}
                                        rows={segmentationDashboard.segmentationDashboard as RegionTableSegmentationC10AndDistriboissons[]}
                                        columns={segmentationTableColumns}
                                        getRowId={(row) => row.id}
                                        rowHeight={height * 0.035}
                                        hideFooter
                                    /> : <LoadingWithoutText />}
                        </ChartBlock>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}