import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import FsCrossDashboardLayout from './layout/FsCrossDashboardLayout'
import { useAppSelector } from '../../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { FsCrossDashboardContent } from './FoodService/FsCrossDashboardContent'



const FsCrossDashboard = () => {
    const { activeFsCrossFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage title="Tableau de bord" period>
            <Filters
                filtersType={FiltersType.FSCROSS}
                filters={activeFsCrossFilters}
            />
            <FsCrossDashboardLayout>
                <FsCrossDashboardContent />
            </FsCrossDashboardLayout>
        </PrivatePage>
    )
}

export default FsCrossDashboard
