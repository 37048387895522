import React from 'react'
import PrivatePage from '../../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../../redux/hooks'
import { WarehousesLayout } from '../../layout/WarehousesLayout'
import { PagesName } from '../../../../../components/features/navigation/types/pages'
import { FsWarehousesPage2Tab } from '../../Pages/FsWarehousesPage2Tab'
import { GBHWarehousesColumns, GBHWarehousesHeadTabColumns } from '../../utils'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { WarehousesTwoList } from '../../../../../redux/fsProducts/fsProductsTypes'

export const WarehousesPageGBH = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const warehouses = useAppSelector((state) => state.fsProducts.warehouses as WarehousesTwoList)

    return (
        <PrivatePage title="Points de vente" attribute={0} period>
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
                page={PagesName.WAREHOUSES}
            />
            <WarehousesLayout >
                {warehouses && <FsWarehousesPage2Tab
                    columnsHeadTab={GBHWarehousesHeadTabColumns("JPB")}
                    titleHeadTab={`Performances par territoire (${warehouses.regionsList.length})`}
                    rowHeadTab={warehouses.regionsList}
                    columnsSecondTab={GBHWarehousesColumns("JPB")}
                    titleSecondTab={`Performances par points de vente  (${warehouses.warehousesList.length})`}
                    rowSecondTab={warehouses.warehousesList}
                    drillDownType={DrillDownType.WAREHOUSESGBH}
                />}
            </WarehousesLayout>
        </PrivatePage >
    )
}
