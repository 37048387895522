import React from 'react';
import { BLACK } from '../../../../../components/ui/Colors';
import { Grid } from '@mui/material';
import { SecondaryText } from '../../../../../components/ui/Text';
import FilterStatement from "../../../../../assets/filter_statement.svg"


export const NoDataPlaceholder = () => {
    return (
        <Grid alignItems="center" justifyContent="center" direction="column" container height="100%" textAlign="center">
            <SecondaryText color={BLACK} mt="1vh">
                Pas de résultat
            </SecondaryText>
            <img
                width="240vw"
                src={FilterStatement}
            />
        </Grid>
    )
}
